export const pt = {
  connect: 'Conectar',
  buy: 'Comprar',
  buyAndStake: 'Comprar e apostar',
  buyWithCard: 'Comprar com cartão',
  stake: 'Apostar',
  stakeNow: 'Apostar agora',
  claimTokens: 'Reivindicar',
  claimAndStake: 'Reivindicar e apostar',
  claimRewards: 'Reivindicar recompensas',
  loading: 'Carregando...',
  viewTransaction: 'Ver transação',
  close: 'Fechar',
  copy: 'Copiar',
  days: 'Dia | Dias',
  hours: 'Hora | Horas',
  minutes: 'Minuto | Minutos',
  seconds: 'Segundo | Segundos',
  lastRound: 'Última rodada',
  developedBy: 'Desenvolvido por',
  navbar: {
    home: 'Início',
    staking: 'Staking',
    about: 'Sobre',
    howToBuy: 'Como comprar',
    tokenomics: 'Tokenomics',
    roadmap: 'Roteiro',
    faq: 'FAQ'
  },
  presale: {
    presaleOpen: 'Pré-venda de {token} está aberta',
    stage: 'Etapa',
    untilPriceIncrease: 'Até o aumento de preço',
    untilPriceIncreaseDescription:
      'O preço irá aumentar quando o tempo da rodada terminar ou quando o USD arrecadado chegar ao alvo.',
    nextPrice: 'Próximo preço',
    usdtRaised: 'USDT arrecadado',
    yourPurchased: 'Seu {token} comprado',
    yourPurchasedDescription:
      'Total de tokens comprados com o endereço conectado na rede selecionada',
    yourStakeable: 'Seu {token} apostável',
    yourStakeableDescription:
      'Total de tokens não apostados com o endereço conectado na rede selecionada. Você pode apostar seus tokens agora indo para a página de staking.',
    yourStaked: 'Seu {token} apostado',
    yourStakedDescription: 'Total de tokens apostados com o endereço conectado na rede selecionada.'
  },
  swap: {
    balance: 'Saldo',
    youPay: '{token} que você paga',
    youReceive: '{token} que você recebe',
    lessThanMinimumError: 'A quantidade de {token} a ser comprada deve ser maior que {min}.',
    exceedsMaxError: 'A quantidade de {token} a ser comprada deve ser menor que {max}.',
    notEnoughBalanceError: 'Certifique-se de que você tenha saldo suficiente para a compra.',
    needApprovalDescription:
      'Para comprar {token} com {usdToken}, você primeiro precisa nos autorizar a acessar o {usdToken} na sua carteira. Você pode precisar verificar o aplicativo da carteira se estiver no celular.',
    waitingApprovalDescription:
      'A autorização está em andamento, você poderá confirmar a transação em breve.',
    needConfirmationDescription:
      'Para comprar tokens {tokenSymbol}, por favor, confirme a transação em sua carteira. Você pode precisar verificar o aplicativo da carteira se estiver no celular.',
    waitingTransactionDescription:
      '{amount} tokens serão comprados assim que a transação for confirmada.',
    openingWertModal: 'Aguarde enquanto a tela de pagamento é aberta.',
    waitingWertPayment:
      'Para comprar {token} com cartão, prossiga preenchendo suas informações na tela de pagamento.',
    waitingWertConfirmation:
      '{amount} tokens serão comprados assim que o provedor de pagamento confirmar seu pagamento.',
    successDescription: '{amount} tokens foram comprados com sucesso!',
    confirmationError:
      'Não conseguimos confirmar sua transação, por favor, verifique se ela está presa na sua carteira.',
    errorDescription: 'Algo deu errado, por favor, tente novamente.',
    startAgain: 'Começar novamente',
    notEnoughTokens: 'Não tem {token} suficiente? Compre ou troque agora!',
    buyOrSwapTokens: 'Compre ou troque tokens'
  },
  presaleOver: {
    presaleOverTitle: 'A pré-venda de {token} terminou',
    presaleOverDescription:
      'Aguarde até que a reivindicação comece para resgatar e negociar seus tokens {token}. Enquanto isso, você pode apostar seus tokens comprados e ganhar recompensas.',
    untilClaimStart: 'Até o início da reivindicação'
  },
  claim: {
    claimOpen: 'Reivindicação de {token} está ativa',
    claimOpenDescription:
      'Agora você pode reivindicar e apostar seus {token} para ganhar recompensas.',
    importToken:
      'Importe o token {token} na sua carteira, usando o endereço abaixo, para ver seus tokens reivindicados.',
    importTokenDescription:
      'Se você não sabe como fazer isso, procure por "Como importar tokens personalizados" na carteira que você está usando.',
    yourClaimable: 'Seus {token} reivindicáveis',
    yourClaimableDescription:
      'Total de tokens disponíveis para reivindicar com o endereço conectado. Você pode reivindicar seus tokens para sua carteira ou apostá-los diretamente.',
    yourStaked: 'Seus {token} apostados',
    yourStakedDescription: 'Total de tokens apostados com o endereço conectado.',
    buyNowOnExchange: 'Compre {token} agora na {exchange}',
    stakeNeedConfirmation:
      'Para apostar seus tokens {token}, por favor confirme a transação em sua carteira. Você precisa verificar o aplicativo da carteira se estiver em um celular. Isso apostará todos os seus tokens {token} disponíveis para reivindicação. Os tokens ficarão bloqueados por {lockDays} @.lower:days até que você possa sacar.',
    claimNeedConfirmation:
      'Para reivindicar seus tokens {token}, por favor confirme a transação em sua carteira. Você precisa verificar o aplicativo da carteira se estiver em um celular. Isso reivindicará todos os seus tokens {token} disponíveis para reivindicação na sua carteira conectada.',
    stakeWaitingTransaction: 'Os tokens serão apostados uma vez que a transação for confirmada.',
    claimWaitingTransaction:
      'Os tokens serão reivindicados uma vez que a transação for confirmada.',
    stakeSuccess: 'Tokens foram apostados com sucesso!',
    claimSuccess:
      'Tokens foram reivindicados com sucesso! Lembre-se, para ver o saldo de {token} na sua carteira, você precisa importar o endereço do token acima.',
    confirmationError:
      'Não conseguimos confirmar sua transação, verifique se está presa em sua carteira.',
    errorDescription: 'Algo deu errado, por favor tente novamente.',
    startAgain: 'Começar de novo'
  },
  staking: {
    welcome: 'Bem-vindo ao staking de {token}',
    distributionDescription:
      'A distribuição de recompensas do token {tokenName} ocorrerá a uma taxa de {rewardsPerDay} {tokenSymbol} por dia e será distribuída ao longo de {duration} ano.',
    withdrawStakedTokens: 'Retirar tokens apostados',
    stakedBalance: 'Saldo apostado',
    stakeableBalance: 'Saldo apostável',
    totalRewards: 'Recompensas totais',
    claimNotStarted:
      'O período de reivindicação começará quando a pré-venda terminar. Fique atento à conclusão da pré-venda para começar a reivindicar suas recompensas.',
    totalStaked: 'Total apostado',
    percentageOfPool: 'Sua % do pool',
    currentRewards: 'Recompensas atuais',
    estimatedRewards: 'Recompensas estimadas',
    rewardRateDynamic: 'A taxa de recompensa é dinâmica',
    monthlyRewards: 'Mensal = Recompensas % / 12',
    dailyRewards: 'Diário = Recompensas % / 365',
    stakeNow: 'Aposte seu {token} agora',
    claimNow: 'Reivindique seu {token} agora',
    withdrawNow: 'Retire seu {token} agora',
    fillAmount:
      'Preencha a quantidade de {token} que você deseja apostar abaixo e confirme a transação para começar a ganhar recompensas.',
    amount: 'Quantidade',
    notEnoughBalanceError: 'Certifique-se de que você tem saldo suficiente para apostar.',
    needApprovalDescription:
      'Para apostar {token}, primeiro você precisa nos autorizar a acessar o {token} em sua carteira. Você precisa verificar o aplicativo da carteira se estiver no celular.',
    waitingApprovalDescription:
      'A autorização está em andamento, você poderá confirmar a transação em breve.',
    stakeNeedConfirmation:
      'Para apostar seus tokens {token}, por favor confirme a transação em sua carteira. Você precisa verificar o aplicativo da carteira se estiver no celular. Isso irá apostar todos os seus tokens {token}. Os tokens ficarão bloqueados por {lockDays} @.lower:days após o processo de reivindicação estar disponível.',
    claimNeedConfirmation:
      'Para reivindicar seus tokens {token}, por favor confirme a transação em sua carteira. Você precisa verificar o aplicativo da carteira se estiver no celular. Isso reivindicará todas as suas recompensas {token}.',
    withdrawNeedConfirmation:
      'Para retirar seus tokens {token}, por favor confirme a transação em sua carteira. Você precisa verificar o aplicativo da carteira se estiver no celular. Isso irá retirar todos os seus tokens {token} apostados.',
    stakeWaitingTransaction: 'Os tokens serão apostados assim que a transação for confirmada.',
    claimWaitingTransaction:
      'As recompensas serão reivindicadas assim que a transação for confirmada.',
    withdrawWaitingTransaction: 'Os tokens serão retirados assim que a transação for confirmada.',
    stakeSuccess: 'Tokens apostados com sucesso!',
    claimSuccess: 'Tokens reivindicados com sucesso!',
    withdrawSuccess: 'Tokens retirados com sucesso!',
    confirmationError:
      'Não conseguimos confirmar sua transação, por favor, verifique se ela está presa na sua carteira.',
    errorDescription: 'Algo deu errado, por favor, tente novamente.',
    tryAgain: 'Tente novamente'
  },
  airdrop: {
    claimTitle: 'Reivindicação de Airdrop {token}',
    claimNotStartedDescription:
      'O período de reivindicação do airdrop começará quando a pré-venda terminar. Fique atento à conclusão da pré-venda para começar a reivindicar suas recompensas.',
    claimStartedDescription:
      'Agora você pode reivindicar seus {token} recebidos no airdrop para ganhar recompensas.',
    importToken:
      'Importe o token {token} na sua carteira, usando o endereço abaixo, para ver seus tokens reivindicados.',
    importTokenDescription:
      'Se você não sabe como fazer isso, procure por "Como importar tokens personalizados" na carteira que você está usando.',
    yourClaimable: 'Seus {token} reivindicáveis',
    yourClaimableDescription:
      'Total de tokens recebidos durante o airdrop disponíveis para reivindicar com o endereço conectado.',
    claimNeedConfirmation:
      'Para reivindicar seus tokens {token}, por favor confirme a transação em sua carteira. Você precisa verificar o aplicativo da carteira se estiver em um celular. Isso reivindicará todos os seus tokens {token} disponíveis para reivindicação na sua carteira conectada.',
    claimWaitingTransaction:
      'Os tokens serão reivindicados uma vez que a transação for confirmada.',
    claimSuccess:
      'Tokens foram reivindicados com sucesso! Lembre-se, para ver o saldo de {token} na sua carteira, você precisa importar o endereço do token acima.',
    confirmationError:
      'Não conseguimos confirmar sua transação, verifique se está presa em sua carteira.',
    errorDescription: 'Algo deu errado, por favor tente novamente.',
    startAgain: 'Começar de novo'
  },
  status: {
    NEED_APPROVAL: 'Precisa de aprovação',
    WAITING_APPROVAL: 'Aguardando confirmação de aprovação',
    NEED_CONFIRMATION: 'Precisa de confirmação',
    WAITING_TRANSACTION: 'Aguardando confirmação da transação',
    OPENINIG_WERT_MODAL: 'Abrindo tela de pagamento',
    WAITING_WERT_PAYMENT: 'Aguardando pagamento',
    WAITING_WERT_CONFIRMATION: 'Aguardando confirmação do pagamento',
    SUCCESS: 'Sucesso',
    ERROR: 'Erro'
  }
}
