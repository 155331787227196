export const indexServices = [
  {
    id: "01",
    title: "Web 3.0 Development",
    description:
      "Token Creation and Smart Contract Development and other solutions for Web 3.0.",
  },
  {
    id: "02",
    title: "Web 3.0 Marketing",
    description:
      "We offer a complete strategic solution for marketing your Web 3.0 project.",
  },
  {
    id: "03",
    title: "Web 3.0 Powered Payments",
    description:
      "We offer Web3-based payment systems enabling secure, decentralized and multi-chain transactions.",
  },
  {
    id: "04",
    title: "Consulting & Legal",
    description:
      "We offer consultancy for company opening, licenses, regulatory compliance and audits for smart contracts.",
  },
];

export const blockChainServices = [
  {
    id: "01",
    title: "Token Development",
    description:
      "Custom token development on blockchain networks like Ethereum, Binance Smart Chain, Solana, and others.",
  },
  {
    id: "02",
    title: "Coin Development",
    description:
      "Development of RWA, meme coins, layer-1 and layer-2, AI and other themed cryptocurrencies.",
  },
  {
    id: "03",
    title: "Wallet Integration",
    description:
      "Integration of secure digital wallets like MetaMask and Trust Wallet for cryptocurrency management.",
  },
  {
    id: "04",
    title: "Whitepaper Creation",
    description:
      "We brainstorm with our blockchain experts to determine the best way to present your project.",
  },
  {
    id: "05",
    title: "Staking Multi-Chain Solution",
    description:
      "Multi-chain staking solutions, enabling rewards for token holders.",
  },
  {
    id: "06",
    title: "Smart Contract Development",
    description:
      "Develop smart contracts for your token. We build on ERC20, BEP2, TRON etc.",
  },
  {
    id: "07",
    title: "ICO Landing Page",
    description:
      "Design and development of ICO and memecoins, with full integration for their launch.",
  },
  {
    id: "08",
    title: "Compliance and Audit",
    description:
      "Regulatory compliance and security audits to ensure the legality and safety of projects.",
  },
  {
    id: "09",
    title: "Airdrop and Giveaways",
    description:
      "Airdrops and giveaways boost brand visibility, attract new users, and encourage engagement, generating positive attention.",
  },
  {
    id: "10",
    title: "Affiliate Program",
    description:
      "Adding an affiliate system increases your brand's reach, generates passive sales, and strengthens strategic marketing partnerships.",
  },
  {
    id: "11",
    title: "Consulting & Legal",
    description:
      "We offer consultancy for company opening, licenses, regulatory compliance and audits for smart contracts.",
  }
];

export const blockchainCoinDetails = [
  {
    title: "Receive Coins",
    icon: "/images/income-down.svg",
  },
  {
    title: "Buy Coins Integration",
    icon: "/images/income-up.svg",
  },
  {
    title: "Staking Multichain",
    icon: "/images/stake.svg",
  },
  {
    title: "Smart Contract Development",
    icon: "/images/smart-contract.svg",
  },
  {
    title: "Claim Token",
    icon: "/images/token.svg",
  },
  {
    title: "Whitepaper Creation",
    icon: "/images/whitepaper.svg",
  },
];

export const termsIndex = [
  {
    title: "MINTWEB3 Community Guidelines:",
    description:
      "At MINTWEB3, we aim to build a thriving, inclusive, and innovative environment that empowers individuals and businesses to embrace....",
    link: "/terms/community-guidelines",
  },
  {
    title: "MINTWEB3 Cookie Policy:",
    description:
      "At MINTWEB3, we aim to build a thriving, inclusive, and innovative environment that empowers individuals and businesses to embrace ...",
    link: "/terms/cookie-policy",
  },
  {
    title: "MINTWEB3 Terms of Service:",
    description: `Welcome to MINTWEB3 ("Company," "we," "us," or "our"). These Terms of Service govern your use of our website, platform, and any...`,
    link: "/terms/terms-of-services",
  },
  {
    title: "MINTWEB3 User Agreement:",
    description: `Welcome to MINTWEB3! This User Agreement ("Agreement") governs your access and use of MINTWEB3’s platform, products, and ....`,
    link: "/terms/user-agreement",
  },
];

export const advertisers = [
  { id: 2, name: "Firebase", logo: "/advertisers/firebase.svg" },
  { id: 3, name: "ChainStack", logo: "/advertisers/chainstack.svg" },
  { id: 1, name: "Github", logo: "/advertisers/github.svg" },
  { id: 4, name: "Python", logo: "/advertisers/python.svg" },
  { id: 5, name: "Flutter", logo: "/advertisers/flutter.svg" },
  { id: 6, name: "React Native", logo: "/advertisers/react-native.svg" },
  { id: 11, name: "Solidity", logo: "/advertisers/solidity.svg" },
  // { id: 7, name: "Node", logo: "/advertisers/node.svg" },
  { id: 8, name: "Morails", logo: "/advertisers/morails.svg" },
  { id: 9, name: "Firebase", logo: "/advertisers/firebase.svg" },
  { id: 10, name: "ChainStack", logo: "/advertisers/chainstack.svg" },
  { id: 11, name: "Github", logo: "/advertisers/github.svg" },
  { id: 12, name: "Python", logo: "/advertisers/python.svg" },
  { id: 13, name: "Flutter", logo: "/advertisers/flutter.svg" },
  { id: 14, name: "React Native", logo: "/advertisers/react-native.svg" },
  { id: 15, name: "Solidity", logo: "/advertisers/solidity.svg" },
  { id: 16, name: "Node", logo: "/advertisers/node.svg" },
  { id: 17, name: "Morails", logo: "/advertisers/morails.svg" },
];

export const coinsUp = [
  { id: 1, name: "Bitcoin", logo: "/coins/btc.webp" },
  { id: 2, name: "Tether", logo: "/coins/usdt.webp" },
  { id: 3, name: "Ton", logo: "/coins/ton.webp" },
  { id: 4, name: "Optimism", logo: "/coins/op.webp" },
  { id: 5, name: "Sol", logo: "/coins/sol.webp" },
  { id: 6, name: "Shiba", logo: "/coins/shiba.webp" },
  { id: 7, name: "USDC", logo: "/coins/usdc.webp" },
  { id: 8, name: "Binance", logo: "/coins/bnb.webp" },
  { id: 9, name: "Polygon", logo: "/coins/pol.webp" },
  { id: 10, name: "Cardano", logo: "/coins/ada.webp" },
];

export const coinsDown = [
  { id: 1, name: "Bitcoin", logo: "/coins/btc.webp" },
  { id: 2, name: "Tether", logo: "/coins/usdt.webp" },
  { id: 3, name: "Ton", logo: "/coins/ton.webp" },
  { id: 4, name: "Optimism", logo: "/coins/op.webp" },
  { id: 5, name: "Sol", logo: "/coins/sol.webp" },
  { id: 6, name: "Shiba", logo: "/coins/shiba.webp" },
  { id: 7, name: "USDC", logo: "/coins/usdc.webp" },
  { id: 8, name: "Binance", logo: "/coins/bnb.webp" },
  { id: 9, name: "Polygon", logo: "/coins/pol.webp" },
  { id: 10, name: "Cardano", logo: "/coins/ada.webp" },
];

export const advertisersCrypto = [
  { id: 1, name: "Ethereum", logo: "/advertisers/ethereum.svg" },
  { id: 2, name: "Arbitrum", logo: "/advertisers/arbitrum.svg" },
  { id: 3, name: "Polygon", logo: "/advertisers/polygon.svg" },
  { id: 4, name: "Binance", logo: "/advertisers/binance.svg" },
  { id: 5, name: "Base", logo: "/advertisers/base.svg" },
  { id: 6, name: "Optimism", logo: "/advertisers/optimism.svg" },
  { id: 7, name: "Ethereum", logo: "/advertisers/ethereum.svg" },
  { id: 8, name: "Arbitrum", logo: "/advertisers/arbitrum.svg" },
  { id: 9, name: "Polygon", logo: "/advertisers/polygon.svg" },
  { id: 10, name: "Binance", logo: "/advertisers/binance.svg" },
  { id: 11, name: "Base", logo: "/advertisers/base.svg" },
  { id: 12, name: "Optimism", logo: "/advertisers/optimism.svg" },
];

export const marketingServices = [
  {
    title: "Whitepaper Optimization",
    description:
      "The whitepaper is the pillar of any Web3 project, serving as a detailed document that explains the technology, vision, and business model behind your project. At MintWeb3, we help you develop or optimize your whitepaper to clearly communicate your project’s goals and value to investors and the community.",
    img: "/images/whitepaper-hero.svg",
    reverse: false,
    items: [
      {
        title: "Clarity and Precision: ",
        description:
          "We ensure your whitepaper is easy to understand while covering the necessary technical details.",
      },
      {
        title: "Compelling Storytelling: ",
        description:
          "We use clear, concise language to engage both technical and non-technical audiences, presenting your project in a way that builds trust.",
      },
    ],
  },

  {
    title: "ICO Landing Page Design",
    description:
      "A well-designed landing page is critical for capturing investor interest during an ICO or presale. Our team creates high-conversion landing pages that highlight your project’s benefits, showcase your team’s credibility, and make it easy for visitors to participate.",
    img: "/images/ico-landing.svg",
    reverse: true,
    items: [
      {
        title: "User-Friendly Design: ",
        description:
          "Our landing pages are designed to be visually appealing and easy to navigate, with clear calls to action.",
      },
      {
        title: "Optimized for Conversions: ",
        description:
          "We focus on creating landing pages that drive sign-ups, token purchases, and community engagement.",
      },
    ],
  },
  {
    title: "ICO Management",
    description:
      "Managing an ICO requires careful planning and execution. MinWeb3 provides full ICO management services, helping you strategize and execute every aspect of your token sale, from marketing and investor outreach to managing the fundraising process.",
    img: "/images/ico-marketing.svg",
    reverse: false,
    items: [
      {
        title: "Fundraising Strategy: ",
        description:
          "We help you develop a strategic approach to your ICO, setting realistic goals and designing an effective tokenomics model.",
      },
      {
        title: "End-to-End Support: ",
        description:
          "We handle everything from presale to token distribution, ensuring a smooth and compliant ICO process.",
      },
    ],
  },
  {
    title: "Exchange Listing Assistance",
    description:
      "Getting your token listed on major cryptocurrency exchanges is key to its success. We assist you in listing your token on top-tier exchanges, ensuring liquidity and visibility for your token once the ICO is complete.",
    img: "/images/exchange-listing.svg",
    reverse: true,
    items: [
      {
        title: "Exchange Research: ",
        description:
          " We identify the best exchanges for your token based on your target market and tokenomics.",
      },
      {
        title: "Application and Negotiation: ",
        description:
          " We handle the application process and negotiate on your behalf to secure favorable listing terms.",
      },
    ],
  },
  {
    title: "ICO Listing on Popular Platforms",
    description:
      "In addition to exchange listings, we help to list your ICO on prominent ICO tracking and listing platforms, giving your project exposure to a broad audience of investors and enthusiasts.",
    img: "/images/ico-platform.svg",
    reverse: false,
    items: [
      {
        title: "Visibility: ",
        description:
          " We help you develop a strategic approach to your ICO, setting realistic goals and designing an effective tokenomics model.",
      },
      {
        title: "Trust and Engagement: ",
        description:
          " Listing on respected platforms helps build trust with potential investors and communities.",
      },
    ],
  },
  {
    title: "Community Management",
    description:
      "Building and managing a vibrant, engaged community is crucial to the long-term success of any Web3 project. We help you manage communities on key platforms like Telegram, Tik Tok, X, Intagram and Discord, ensuring that your supporters stay informed, engaged, and excited about your project.",
    img: "/images/community-management.svg",
    reverse: true,
    items: [
      {
        title: "Community Growth: ",
        description:
          " We help grow your community through active engagement, contests, and content creation.",
      },
      {
        title: "24/7 Moderation: ",
        description:
          " We handle the application process and negotiate on your behalf to secure favorable listing terms.",
      },
    ],
  },
  {
    title: "Website Audit and SEO",
    description:
      "Your project’s website is often the first point of contact for potential investors and users. We perform a detailed audit of your website to ensure it is optimized for search engines (SEO) and delivers a smooth user experience.",
    img: "/images/web-audit.svg",
    reverse: false,
    items: [
      {
        title: "SEO Optimization: ",
        description:
          " We implement SEO best practices to ensure your website ranks higher on search engines and attracts organic traffic.",
      },
      {
        title: "User Experience (UX) Improvement: ",
        description:
          "User Experience (UX) Improvement : We assess and improve your website’s usability, making it easier for visitors to navigate and take action.",
      },
    ],
  },
  {
    title: "Copywriting for Web3 Projects",
    description:
      "Content is key in building a strong narrative around your Web3 project. Our team of skilled writers creates high-quality content for every stage of your project, from whitepapers and blog posts to social media updates and press releases.",
    img: "/images/copywriting.svg",
    reverse: true,
    items: [
      {
        title: "Engaging Content: ",
        description:
          " We write clear, compelling content that communicates the value of your project to a wide audience.",
      },
      {
        title: "Consistency Across Channels: ",
        description:
          " From your website to your social media accounts, we ensure consistent messaging and tone across all platforms.",
      },
    ],
  },
  {
    title: "Graphic and Video Content Creation",
    description:
      "Visual content is essential in capturing attention and conveying complex ideas quickly. Our creative team designs graphics, videos, and animations that make your project stand out and communicate your vision effectively.",
    img: "/images/video-creation.svg",
    reverse: false,
    items: [
      {
        title: "Explainer Videos: ",
        description:
          " We create engaging explainer videos that break down your project’s technology and benefits.",
      },
      {
        title: "Graphic Design: ",
        description:
          " From logo creation to banner ads, we design visual assets that enhance your brand’s visibility and appeal.",
      },
    ],
  },
  {
    title: "Sticker Sets and GIFs",
    description:
      "Boost engagement on social media and community platforms with fun, branded sticker sets and GIFs that resonate with your audience. These assets are perfect for increasing visibility during airdrops, giveaways, and community events.",
    img: "/images/stickers.svg",
    reverse: true,
    items: [
      {
        title: "Custom Stickers: ",
        description:
          " We create unique sticker sets that reflect your project’s personality and branding.",
      },
      {
        title: "Promotional GIFs: ",
        description:
          " Shareable GIFs that can be used across social media platforms to increase your project’s visibility.",
      },
    ],
  },
  {
    title: "Promotional Banners",
    description:
      "Our design team creates eye-catching banners for use on websites, blogs, and social media. These banners are designed to promote key aspects of your project, such as upcoming events, token sales, or new features.",
    img: "/images/promotion-banner.svg",
    reverse: false,
    items: [
      {
        title: "High-Quality Design: ",
        description:
          " Our banners are visually striking and designed to capture attention.",
      },
      {
        title: "Targeted Messaging: ",
        description:
          " Each banner communicates a clear, actionable message that drives interest and engagement.",
      },
    ],
  },
  {
    title: "Influencer and Viral Marketing",
    description:
      "Leverage the power of influencers and viral content to increase your project’s reach. We partner with top crypto influencers and implement viral marketing campaigns that generate buzz around your project.",
    img: "/images/viral-marketing.svg",
    reverse: true,
    items: [
      {
        title: "Influencer Collaborations: ",
        description:
          " We connect you with leading influencers in the crypto space to promote your project to their audience.",
      },
      {
        title: "Viral Marketing Campaigns: ",
        description:
          " We create content designed to go viral, driving maximum exposure and engagement.",
      },
    ],
  },
];

export const airdropServices = [
  {
    title: "Airdrop Gaming",
    description:
      "Our Airdrop Telegram Game Solution offers an interactive and engaging way to distribute tokens through a Click-to-Earn game. Users can participate directly within your Telegram group by completing simple tasks, such as clicking on links, answering questions, or performing actions that unlock airdrop rewards. This gamified approach not only drives more participation but also boosts community interaction and excitement. It’s an effective tool to grow your Telegram community while rewarding users for their engagement. The solution is easy to set up and integrates seamlessly with Telegram, providing a fun and rewarding experience for all participants.",
    img: "/images/whitepaper-hero.svg",
    reverse: false,
    items: [
      {
        title: "Increased Engagement: ",
        description:
          "Gamifies the airdrop process, encouraging more participation.",
      },
      {
        title: "Easy Telegram Integration: ",
        description:
          "Seamlessly connects with your existing Telegram community.",
      },
      {
        title: "Boosts Community Growth: ",
        description:
          "Drives new users and retains existing participants with fun rewards.",
      },
    ],
  },

  {
    title: "ICO Landing Page Design",
    description:
      "A well-designed landing page is critical for capturing investor interest during an ICO or presale. Our team creates high-conversion landing pages that highlight your project’s benefits, showcase your team’s credibility, and make it easy for visitors to participate.",
    img: "/images/ico-landing.svg",
    reverse: true,
    items: [
      {
        title: "User-Friendly Design: ",
        description:
          "Our landing pages are designed to be visually appealing and easy to navigate, with clear calls to action.",
      },
      {
        title: "Optimized for Conversions: ",
        description:
          "We focus on creating landing pages that drive sign-ups, token purchases, and community engagement.",
      },
    ],
  }
];

export const stakingServices = [
  {
    title: "Key Features",
    description:
      "Mint Web3 Staking Services provide a secure, customizable solution for token projects looking to increase user engagement and incentivize long-term holding. Our staking widget can be easily integrated into your platform, offering a seamless experience for users to stake across multiple blockchain networks. The widget is fully customizable, allowing you to adjust it to match your brand and tokenomics. By offering flexible staking models like single-token and liquidity pool staking, MintWeb3 helps create an ecosystem that suits your specific goals and reward structures.",
    img: "/images/whitepaper-hero.svg",
    reverse: false,
    items: [
      {
        title: "Multi-Chain Staking: ",
        description:
          "Stake across various blockchain networks for broader reach.",
      },
      {
        title: "Customizable Widget: ",
        description:
          "Tailor the staking interface to your branding and tokenomics.",
      },
      {
        title: "User-Friendly Interface: ",
        description:
          "Seamless integration for an intuitive staking experience.",
      },
    ],
  },
  {
    title: "Key Benefits",
    description:
      "Reducing circulating supply through staking, projects can create scarcity, which can positively impact token value. This fosters a more engaged and loyal community while supporting the long-term growth of your ecosystem. We use industry-leading encryption protocols to ensure the safety of all staked assets, offering peace of mind to participants.",
    img: "/images/ico-landing.svg",
    reverse: true,
    items: [
      {
        title: "Robust Security: ",
        description:
          "Advanced encryption to protect staked assets.",
      },
      {
        title: "Increased Token Value: ",
        description:
          "Reducing circulating supply through staking can enhance token scarcity.",
      },
      {
        title: "Community Engagement: ",
        description:
          "Incentivizing token holders leads to greater loyalty and participation.",
      },
    ],
  }
];