<script setup>
import { useSlots } from 'vue';

const slots = useSlots()
</script>

<template>
    <div class="text-4xl font-bold">
        <span v-if="slots.before" style="position: relative;">
            <div class="top-left d-none d-md-block">
                <img src="/images/arrow.svg" alt="Arrow" width="24" height="24" />
            </div>
            <span class="bg-primary text-white px-2 me-2">
                <slot name="before"></slot>
            </span>
        </span>
        <slot />
        <span v-if="slots.after" style="position: relative;">
            <span class="text-white bg-primary px-2">
                <slot name="after"></slot>
            </span>
            <div class="bottom-right d-none d-md-block">
                <img src="/images/arrow.svg" alt="Arrow" width="24" height="24" >
            </div>
        </span>
        <span v-if="slots.afterText">
            <slot name="afterText" />
        </span>
    </div>
</template>

<style scoped>
.top-left {
    position: absolute;
    left: -26px;
    /* top: -50px; */
    top: -70%;
}

.bottom-right {
    position: absolute;
    right: -26px;
    /* top: 50px; */
    top: 65%;
    transform: rotate(180deg);
}
</style>