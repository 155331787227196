<script setup>
import { advertisers, stakingServices, advertisersCrypto, coinsUp, coinsDown } from '../assets/data/index';
import MarketingCard from '../components/base/MarketingCard.vue';
import Title from '../components/base/Title.vue';
import { useAccount } from '@/composables/account'
import { useContract } from '@/composables/contract'
import { useChain } from '@/composables/chain'
import { useData } from '@/composables/data'
import { chains } from '@/plugins/walletconnect'
import OutlinedBtn from '../components/base/OutlinedBtn.vue';
import SecondaryBtn from '../components/base/SecondaryBtn.vue';
import { OperationType, WriteContractStatus } from '@/utils/constants/enums'
import AdvertisersMarquee from '../components/AdvertisersMarquee.vue';
import CoinsMarquee from '../components/CoinsMarquee.vue';
import { Vue3Lottie } from 'vue3-lottie'
import Hero2 from '@/assets/hero-2.json'
import {
  formatNumber,
  getChainSymbol,
  handleKeyDown,
  hasClaimStarted,
  openModal
} from '@/utils/helpers'
import { formatEther } from 'viem'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
const { account, accountBalances } = useAccount()
const { selectedChain, update } = useChain()
const { userUnstakedBalance, stakerDetails, stakerRewards, rewardsPerDay, totalStaked, presaleEnded } = useData()
const {
  isLoading,
  operation,
  transactionHash,
  status,
  stake,
  claimStake,
  deposit,
  withdraw,
  clearState
} = useContract()

const tokenName = import.meta.env.VITE_PRESALE_TOKEN_NAME
const tokenSymbol = import.meta.env.VITE_PRESALE_TOKEN_SYMBOL
const stakingDuration = import.meta.env.VITE_STAKING_DURATION_YEARS
const claimLockDays = import.meta.env.VITE_CLAIM_LOCK_DAYS

const amount = ref('')

const userBalance = computed(() => {
  return hasClaimStarted()
    ? (accountBalances.value.get(tokenSymbol)?.formatted ?? '0')
    : formatEther(userUnstakedBalance.value)
})

const hasAmount = computed(() => {
  return amount.value !== '' && Number(amount.value) > 1
})

const hasEnoughBalance = computed(() => {
  return Number(userBalance.value) >= Number(amount.value)
})

const percentageOfPool = computed(() => {
  return totalStaked.value > 0
    ? formatNumber(
      (Number(formatEther(stakerDetails.value.amount)) / Number(formatEther(totalStaked.value))) *
      100,
      0,
      2
    )
    : 0
})

const rewardsPerDayFormatted = computed(() => {
  return formatNumber(formatEther(rewardsPerDay.value), 0, 0)
})

const rewardsPerYear = computed(() => {
  return totalStaked.value > 0
    ? formatNumber(
      (Number(formatEther(rewardsPerDay.value * 365n)) / Number(formatEther(totalStaked.value))) *
      100,
      0,
      2
    )
    : 0
})

const hasTokensToClaim = computed(() => {
  const now = new Date().getTime() / 1000
  const lockDaysInSeconds = Number(claimLockDays) * 86400

  return (
    stakerRewards.value > 0n &&
    Number(stakerDetails.value.stakedTime) + lockDaysInSeconds <= now &&
    Number(import.meta.env.VITE_CLAIM_START_TIME) + lockDaysInSeconds <= now
  )
})

const hasTokensToWithdraw = computed(() => {
  const now = new Date().getTime() / 1000
  const lockDaysInSeconds = Number(claimLockDays) * 86400

  return (
    stakerDetails.value.amount > 0n &&
    Number(stakerDetails.value.stakedTime) + lockDaysInSeconds <= now &&
    Number(import.meta.env.VITE_CLAIM_START_TIME) + lockDaysInSeconds <= now
  )
})

function openTransaction() {
  const url = selectedChain.value.blockExplorers.default.url + '/tx/' + transactionHash.value
  window.open(url, '_blank')
}

function maxAmount() {
  amount.value = Number(userBalance.value).toFixed(0)
}
</script>

<template>
  <main>
    <section>
      <div class="radial-background-left-mobile"></div>
      <div class="radial-background-right-mobile"></div>
      <CoinsMarquee class="image-mobile" :data="coinsUp" :direction="'reverse'" />

      <div class="hero-2">
        <vue3-lottie class="lottie-image" :animation-data="Hero2" :loop="true" :autoplay="true" />
        <div class="hero-2-content ">
          <div class="min-vh-100 d-flex" style="flex-direction: column;justify-content: center; gap: 70px;"
            data-aos="fade-up">
            <div class="container">
              <div class="row  align-items-center justify-content-center text-center">
                <div class="col-md-10">
                  <OutlinedBtn>
                    <template #title>

                      <div class="d-flex align-items-center gap-1">

                        Join Us Free World
                        <img src="/images/right-arrow.svg" alt="Right arrow" width="16" height="16" />
                      </div>
                    </template>
                  </OutlinedBtn>
                  <h1 class="display-2 text-white fw-bold my-4">
                    <Title>
                      <span class="text-white">WEB 3.0</span>
                      <br />
                      <template #after>STAKING</template>
                    </Title>
                  </h1>
                  <p class="text-secondary-light mx-auto" style="max-width: 70%;">
                    We offer customizable, secure, multi-chain support, flexible models,
                    to drive community engagement and token value growth. </p>
                  <div class="mt-4">
                    <SecondaryBtn title="Discover Those Worlds" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CoinsMarquee class="image-mobile" :data="coinsDown" />
    </section>

    <AdvertisersMarquee :dark="true" :borders="true" :data="advertisers" />

    <div class="">
      <div class="bg-white position-relative mt-lg-2 pb-3">
        <div class="radial-background-left"></div>
        <div class="radial-background-right"></div>
        <div class="container mb-2 pt-5">
          <div class="row d-flex align-items-center">
            <div class="col-lg-7 col-12">
              <h2 class="display-2 fw-bold pb-3 pe-2 m-0 text-uppercase text-start">
                {{ $t('staking.welcome', { token: tokenName }) }}
              </h2>
              <p class="font-18">
                {{
                  $t('staking.distributionDescription', {
                    tokenName: tokenName,
                    rewardsPerDay: rewardsPerDayFormatted,
                    tokenSymbol: tokenSymbol,
                    duration: stakingDuration
                  })
                }}
              </p>
            </div>
            <div class="col-lg-5 col-12 justify-content-md-end d-flex mb-3 mb-lg-0">
              <button class="btn btn-primary text-uppercase"
                :class="[!account.isConnected || !hasTokensToWithdraw ? 'disabled' : '']" @click="() => {
                  if (account.isConnected && hasTokensToWithdraw) {
                    withdraw()
                  }
                }
                  " :data-bs-toggle="!account.isConnected || !hasTokensToWithdraw ? '' : 'modal'"
                :data-bs-target="!account.isConnected || !hasTokensToWithdraw ? '' : '#operationModal'">
                {{ $t('staking.withdrawStakedTokens') }}
              </button>
            </div>

            <div class="col-12 mt4">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-xl-3 mb-3 mb-xl-0">
                  <div class="card h-100">
                    <div class="card-body d-flex flex-column justify-content-between">
                      <div>
                        <div v-if="!hasClaimStarted()" class="dropdown mb-3">
                          <button type="button"
                            class="btn w-100 border py-2 px-0 px-2 dropdown-toggle d-flex justify-content-between align-items-center"
                            data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
                            <img :src="`/coins-icons/${getChainSymbol(selectedChain.id)}.svg`"
                              :alt="`${getChainSymbol(selectedChain.id)} logo`" width="22" height="22" class="me-2" />
                            <span class="text-truncate">{{ selectedChain.name }}</span>
                          </button>

                          <ul class="dropdown-menu">
                            <li v-for="chain in chains" :key="chain.id">
                              <button class="dropdown-item d-flex align-items-center ps-2"
                                :class="chain.id === selectedChain.id ? 'active' : ''"
                                :aria-current="chain.id === selectedChain.id" type="button" @click="update(chain.id)">
                                <img :src="`/coins-icons/${getChainSymbol(chain.id)}.svg`"
                                  :alt="`${getChainSymbol(selectedChain.id)} logo`" loading="lazy" width="22"
                                  height="22" class="me-2" />
                                {{ chain.name }}
                              </button>
                            </li>
                          </ul>
                        </div>

                        <div>
                          <span class="font-14 text-uppercase mb-1 d-inline-block">{{
                            $t('staking.stakedBalance')
                          }}</span>
                          <h2 class="font-25 mt-2 d-flex align-items-start">
                            {{ formatNumber(formatEther(stakerDetails.amount), 0, 0) }}
                            <span class="d-inline-block ms-2 font-12">{{ tokenSymbol }}</span>
                          </h2>
                        </div>

                        <div class="my-3">
                          <span class="font-14 text-uppercase mb-1 d-inline-block">{{
                            $t('staking.stakeableBalance')
                          }}</span>
                          <h2 class="font-25 mt-2 d-flex align-items-start">
                            {{ formatNumber(userBalance, 0, 0) }}
                            <span class="d-inline-block ms-2 font-12">{{ tokenSymbol }}</span>
                          </h2>
                        </div>
                      </div>

                      <button v-if="!account.isConnected" type="button"
                        class="btn btn-primary fs-6 w-100 text-uppercase" @click="openModal">
                        {{ $t('connect') }}
                      </button>
                      <RouterLink v-else-if="Number(userBalance) === 0 && !hasClaimStarted()"
                        :to="{ path: `/${locale}/payments` }" class="btn btn-primary fs-6 w-100 text-uppercase">
                        {{ $t('buyAndStake') }}
                      </RouterLink>
                      <button v-else-if="!presaleEnded || hasClaimStarted()" type="button"
                        class="btn btn-primary fs-6 w-100 text-uppercase" @click="() => {
                          hasClaimStarted() ? null : stake()
                        }
                          " data-bs-toggle="modal" data-bs-target="#operationModal">
                        {{ $t('stake') }}
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-xl-3 mb-3 mb-xl-0">
                  <div class="card h-100">
                    <div class="card-body h-100 d-flex flex-column justify-content-between">
                      <div>
                        <span class="font-14 text-uppercase mb-1 d-inline-block">{{
                          $t('staking.totalRewards')
                        }}</span>
                        <h2 class="font-25 mt-2 d-flex align-items-start">
                          {{ formatNumber(formatEther(stakerRewards), 0, 0) }}
                          <span class="d-inline-block ms-2 font-12">{{ tokenSymbol }}</span>
                        </h2>
                      </div>

                      <div>
                        <p v-if="!hasClaimStarted()" class="p-0 my-3 font-13">
                          {{ $t('staking.claimNotStarted') }}
                        </p>

                        <button class="btn btn-primary fs-6 w-100 text-uppercase" :class="[
                          !account.isConnected || !hasClaimStarted() || !hasTokensToClaim
                            ? 'disabled'
                            : ''
                        ]" @click="() => {
                          if (account.isConnected && hasClaimStarted() && hasTokensToClaim) {
                            claimStake()
                          }
                        }
                          " :data-bs-toggle="!account.isConnected || !hasTokensToWithdraw ? '' : 'modal'"
                          :data-bs-target="!account.isConnected || !hasTokensToWithdraw ? '' : '#operationModal'
                            ">
                          {{ $t('claimRewards') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-xl-3 mb-3 mb-md-0">
                  <div class="card h-100">
                    <div class="card-body">
                      <div>
                        <span class="font-14 text-uppercase mb-1 d-inline-block">{{
                          $t('staking.totalStaked')
                        }}</span>
                        <h2 class="font-25 mt-2 d-flex align-items-start">
                          {{ formatNumber(formatEther(totalStaked), 0, 0) }}
                          <span class="d-inline-block ms-2 font-12">{{ tokenSymbol }}</span>
                        </h2>
                      </div>

                      <div class="my-3">
                        <span class="font-14 text-uppercase mb-1 d-inline-block">{{
                          $t('staking.percentageOfPool')
                        }}</span>
                        <h2 class="font-25 mt-2 d-flex align-items-start">{{ percentageOfPool }}%</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-xl-3 mb-3 mb-md-0">
                  <div class="card h-100">
                    <div class="card-body h-100 d-flex flex-column justify-content-between">
                      <div>
                        <span class="font-14 text-uppercase mb-1 d-inline-block">
                          {{ $t('staking.currentRewards') }}
                        </span>
                        <h2 class="font-25 mt-2 d-flex align-items-start">
                          {{ rewardsPerDayFormatted }}
                          <span class="d-inline-block ms-2 font-12 text-lowercase">{{ tokenSymbol }}/{{ $t('days', 1)
                            }}</span>
                        </h2>

                        <span class="font-14 text-uppercase mt-3 mb-1 d-inline-block">
                          {{ $t('staking.estimatedRewards') }}
                        </span>
                        <h2 class="font-25 mt-2 d-flex align-items-start">
                          {{ rewardsPerYear }}%
                          <span class="d-inline-block ms-2 font-12">p/y</span>
                        </h2>
                      </div>

                      <dl class="p-0 mt-3 mb-0 font-13">
                        <dt></dt>
                        <dd class="mb-1">
                          <img src="../assets/icons/arrow.svg" width="8" height="8" alt="Arrow" class="me-1" />
                          {{ $t('staking.rewardRateDynamic') }}
                        </dd>
                        <dt></dt>
                        <dd class="mb-1">
                          <img src="../assets/icons/arrow.svg" width="8" height="8" alt="Arrow" class="me-1" />
                          {{ $t('staking.monthlyRewards') }}
                        </dd>
                        <dt></dt>
                        <dd class="mb-1">
                          <img src="../assets/icons/arrow.svg" width="8" height="8" alt="Arrow" class="me-1" />
                          {{ $t('staking.dailyRewards') }}
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade" id="operationModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="operationModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="d-flex w-100 justify-content-between mb-5">
                    <div></div>
                    <h1 v-if="operation === OperationType.Stake || operation === ''" class="modal-title fs-5"
                      id="operationModalLabel">
                      {{ $t('staking.stakeNow', { token: tokenSymbol }) }}
                    </h1>
                    <h1 v-if="operation === OperationType.Claim" class="modal-title fs-5" id="operationModalLabel">
                      {{ $t('staking.claimNow', { token: tokenSymbol }) }}
                    </h1>
                    <h1 v-if="operation === OperationType.Withdraw" class="modal-title fs-5" id="operationModalLabel">
                      {{ $t('staking.withdrawNow', { token: tokenSymbol }) }}
                    </h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="() => {
                      clearState()
                      amount = ''
                    }
                      "></button>
                  </div>

                  <div v-if="hasClaimStarted() && !isLoading && status === ''">
                    <div class="row my-2 align-items-center justify-content-center">
                      <p class="font-15 pb-2 text-center">
                        {{ $t('staking.fillAmount', { token: tokenSymbol }) }}
                      </p>
                      <div class="col-md-6 pe-md-2">
                        <div class="d-flex align-items-center justify-content-between mb-2">
                          <label for="amount" class="d-block font-13">
                            {{ $t('staking.amount', { token: tokenSymbol }) }}
                          </label>
                          <span class="font-13 cursor-pointer" @click="maxAmount">MAX</span>
                        </div>
                        <div class="input-group d-flex align-items-start">
                          <input type="text" v-model="amount" id="amount" @keydown="handleKeyDown($event, true)"
                            placeholder="0" class="form-control text-truncate" />
                          <span class="input-group-text">
                            <img :src="`/coins-icons/${tokenSymbol}.svg`" :alt="`${tokenSymbol} logo`" width="24"
                              height="24" />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="text-center">
                      <p v-if="!hasEnoughBalance" class="text-danger font-13">
                        {{ $t('staking.notEnoughBalanceError') }}
                      </p>
                    </div>

                    <div class="d-grid w-100 align-items-center mt-4">
                      <div class="d-flex flex-column flex-md-row w-100 gap-2">
                        <button type="button" class="btn btn-primary w-100"
                          :class="[!hasAmount || !hasEnoughBalance ? 'disabled' : '']" @click="() => {
                            if (!hasAmount || !hasEnoughBalance) return
                            deposit(amount)
                          }
                            ">
                          {{ $t('stake') }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-else class="d-flex flex-column w-100 align-items-center justify-content-center gap-2 mt-4">
                    <div v-if="isLoading" class="spinner-border text-primary m-2">
                      <span class="visually-hidden">{{ $t('loading') }}</span>
                    </div>

                    <img v-if="status === WriteContractStatus.Success" src="../assets/icons/success.svg"
                      alt="Success icon" width="55" height="55" />
                    <img v-else-if="status === WriteContractStatus.Error" src="../assets/icons/error.svg"
                      alt="Error icon" width="55" height="55" />

                    <div v-if="status !== ''" class="fw-normal fs-6 mt-1 text-uppercase text-center" :class="status === WriteContractStatus.Error
                      ? 'text-danger'
                      : status === WriteContractStatus.Success
                        ? 'text-success'
                        : 'text-secondary'
                      ">
                      {{ $t('status.' + status.description) }}
                    </div>

                    <p v-if="status === WriteContractStatus.NeedApproval" class="font-14 mb-3 text-black">
                      {{ $t('staking.needApprovalDescription', { token: tokenSymbol }) }}
                    </p>

                    <p v-if="status === WriteContractStatus.WaitingApproval" class="font-14 mb-3 text-black">
                      {{ $t('staking.waitingApprovalDescription') }}
                    </p>

                    <div v-if="status === WriteContractStatus.NeedConfirmation">
                      <p class="font-14 mb-3 text-center">
                        <span v-if="operation === OperationType.Stake">
                          {{
                            $t(
                              'staking.stakeNeedConfirmation',
                              {
                                token: tokenSymbol,
                                lockDays: claimLockDays
                              },
                              claimLockDays
                            )
                          }}
                        </span>
                        <span v-if="operation === OperationType.Claim">
                          {{ $t('staking.claimNeedConfirmation', { token: tokenSymbol }) }}
                        </span>
                        <span v-if="operation === OperationType.Withdraw">
                          {{ $t('staking.withdrawNeedConfirmation', { token: tokenSymbol }) }}
                        </span>
                      </p>
                    </div>

                    <div v-if="status === WriteContractStatus.WaitingTransaction">
                      <p class="font-14 mb-3 text-center">
                        <span v-if="operation === OperationType.Stake">{{
                          $t('staking.stakeWaitingTransaction')
                        }}</span>
                        <span v-if="operation === OperationType.Claim">{{
                          $t('staking.claimWaitingTransaction')
                        }}</span>
                        <span v-if="operation === OperationType.Withdraw">{{
                          $t('staking.withdrawWaitingTransaction')
                        }}</span>
                      </p>
                    </div>

                    <div v-if="
                      status === WriteContractStatus.Success || status === WriteContractStatus.Error
                    " class="w-100">
                      <div v-if="status === WriteContractStatus.Success">
                        <p class="font-14 mb-3 text-center">
                          <span v-if="operation === OperationType.Stake">{{
                            $t('staking.stakeSuccess')
                          }}</span>
                          <span v-if="operation === OperationType.Claim">{{
                            $t('staking.claimSuccess')
                          }}</span>
                          <span v-if="operation === OperationType.Withdraw">{{
                            $t('staking.withdrawSuccess')
                          }}</span>
                        </p>
                      </div>
                      <p v-else-if="transactionHash" class="font-14 mb-3 text-center text-danger">
                        {{ $t('staking.confirmationError') }}
                      </p>
                      <p v-else class="font-14 mb-3 text-center text-danger">
                        {{ $t('staking.errorDescription') }}
                      </p>

                      <div class="d-flex flex-column flex-md-row w-100 gap-2 mt-4">
                        <button type="button" class="btn btn-secondary w-100" data-bs-dismiss="modal" aria-label="Close"
                          @click="() => {
                            clearState()
                            amount = ''
                          }
                            ">
                          {{ $t('close') }}
                        </button>
                        <button v-if="transactionHash" type="button" class="btn btn-primary w-100"
                          @click="openTransaction">
                          {{ $t('viewTransaction') }}
                        </button>
                        <button v-else type="button" class="btn btn-primary w-100" @click="() => {
                          const op = operation
                          clearState()
                          switch (op) {
                            case OperationType.Stake:
                              if (!hasClaimStarted()) {
                                stake()
                              }
                              break
                            case OperationType.Claim:
                              claimStake()
                              break
                            case OperationType.Withdraw:
                              withdraw()
                              break
                          }
                        }
                          ">
                          {{ $t('staking.tryAgain') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-container">
      <h1 class="display-5 fw-bold text-center text-white">
        <Title>
          Staking <template #after>Advantages</template>
        </Title>
      </h1>
    </div>

    <div class="position-relative">
      <!-- gradiant bgs start-->
      <div class="radial-background-left"></div>
      <!-- gradiant bgs-end -->
      <div class="container py-4">
        <div v-for="(item, idx) in stakingServices.slice(0, 1)" :key="idx" class="my-5">
          <MarketingCard :dark="true" :item="item" />
        </div>
      </div>
    </div>

    <!-- <div class="bg-white">
      <div class="container py-4">
        <div v-for="(item, idx) in stakingServices.slice(1, 2)" :key="idx" class="my-5">
          <MarketingCard :item="item" />
        </div>
      </div>
    </div> -->

    <div class="bg-white position-relative">
      <!-- gradiant bgs start-->
      <div class="radial-background-right"></div>
      <!-- gradiant bgs-end -->
      <div class="container py-4">
        <div v-for="(item, idx) in stakingServices.slice(1, 2)" :key="idx" class="my-5">
          <MarketingCard :dark="false" :item="item" />
        </div>
      </div>
    </div>

    <div>
      <AdvertisersMarquee :dark="true" :borders="true" title="Multi Network" :data="advertisersCrypto" />
    </div>

  </main>
</template>

<style scoped>
.center-gradient {
  position: absolute;
  top: 50%;
  right: 0%;
  width: 100%;
  height: 100%;
  background: radial-gradient(50% 20% at 50% 20%, #25DA4A 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0.3;

  transform: translateY(-50%);
  z-index: -1;
  pointer-events: none;
}

.right-2-gradient {
  position: absolute;
  top: 97%;
  right: 0%;
  width: 100%;
  height: 100%;
  background: radial-gradient(40% 13% at 100% 30%, #25DA4A 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0.5;

  transform: translateY(-50%);
  z-index: -1;
  pointer-events: none;
}

.radial-background-left {
  position: absolute;
  top: 50%;
  left: 0%;
  width: 100%;
  height: 100%;
  background: radial-gradient(30% 50% at 50% 50%, #25DA4A 0%, rgba(255, 255, 255, 0) 100%);
  transform: translate(-50%, -50%);
  z-index: 0;
  pointer-events: none;
  opacity: 40%;
}

.radial-background-right {
  position: absolute;
  top: 50%;
  right: 0%;
  width: 100%;
  height: 100%;
  background: radial-gradient(40% 50% at 100% 50%, #25DA4A 0%, rgba(255, 255, 255, 0) 100%);
  transform: translateY(-50%);
  z-index: 0;
  pointer-events: none;
  opacity: 40%;
}
</style>