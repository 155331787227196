// import "bootstrap/dist/css/bootstrap.min.css";
import './assets/custom.scss'
import './assets/style.css'
import './assets/css/main.css'

import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import { i18n } from './plugins/i18n'
import router from './router'
import { tooltip } from './directives/tooltip'
import vueGtag from 'vue-gtag'
import { VueRecaptchaPlugin } from 'vue-recaptcha'
import { web3modal } from './plugins/walletconnect'

import App from './App.vue'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Vue3Lottie from 'vue3-lottie'
import Vue3Marquee from 'vue3-marquee'

AOS.init({})

const pinia = createPinia()
const head = createHead()
const app = createApp(App)

app.use(
  vueGtag,
  {
    config: { id: 'G-039NHXW8TP' },
    disableScriptLoad: true
  },
  router
)
app.directive('tooltip', tooltip)
app.use(i18n)
app.use(router)
app.use(head)
app.use(pinia)
app.use(web3modal)
app.use(Vue3Lottie)
app.use(VueRecaptchaPlugin, {
  v2SiteKey: import.meta.env.VITE_RECAPTCHA_V3_SITEKEY
})
app.use(Vue3Marquee)
app.mount('#app')
