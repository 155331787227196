import { onMounted, onUnmounted, toValue, watch } from 'vue'
import { ref } from 'vue'

export function useCountdown(finalDate) {
  let intervalId
  const seconds = ref(0)
  const minutes = ref(0)
  const hours = ref(0)
  const days = ref(0)

  function getTime() {
    const now = Math.floor(Date.now() / 1000)

    if (toValue(finalDate) < now) {
      days.value = 0
      hours.value = 0
      minutes.value = 0
      seconds.value = 0

      return
    }

    const remainingSeconds = toValue(finalDate) - now

    days.value = Math.floor(remainingSeconds / (60 * 60 * 24))
    hours.value = Math.floor((remainingSeconds % (60 * 60 * 24)) / (60 * 60))
    minutes.value = Math.floor((remainingSeconds % (60 * 60)) / 60)
    seconds.value = Math.floor(remainingSeconds % 60)
  }

  onMounted(() => {
    intervalId = setInterval(getTime, 1000)
  })

  onUnmounted(() => clearInterval(intervalId))

  watch(finalDate, () => {
    getTime()
  })

  return {
    seconds,
    minutes,
    hours,
    days
  }
}
