<template>
    <div>
      <div class="d-flex align-items-center my-3 justify-content-center py-3">
        <div v-if="borders" class="flex-grow-1 border-top"
          :class="props.dark ? 'border-secondary-light' : 'border-secondary-dark'">
        </div>
        <div class=" fw-semibold text-center mx-3" :class="props.dark ? 'text-secondary-light' : 'text-secondary-dark'">
          {{ title }}
        </div>
        <div v-if="borders" class="flex-grow-1 border-top"
          :class="props.dark ? 'border-secondary-light' : 'border-secondary-dark'">
        </div>
      </div>
  
      <section class="coins-section py-5">
        <Vue3Marquee :direction=props.direction :pause-on-click="true" class="pb-5">
          <div v-for="coin in data" :key="coin.id"  class="px-5">
            <img :src="coin.logo" :alt="coin.name" width="64" height="64" class="coin-logo"
              :class="props.dark ? 'filter-logo' : ''">
          </div>
        </Vue3Marquee>
      </section>
    </div>
  </template>
  
  <script setup>
  const props = defineProps({
    dark: {
      default: false,
      type: Boolean
    },
    title: {
      default: '',
      type: String
    },
    borders: {
      default: false,
      type: Boolean
    },
    data: {
      default: [],
      type: Array
    },
    direction: {
      default: "''normal''",
      type: String
    },
  })
  
  </script>
  
  <style scoped>
  .coins-section {
    overflow: hidden;
  }
  
  .coin-logo {
    /* height: 40px; */
    /* opacity: 0.5; */
    /* transition: opacity 0.4s ease; */
  }
  
  .filter-logo {
    filter: brightness(0) invert(1);
  }
  
  .coin-logo:hover {
    opacity: 1;
  }
  </style>