import { hasClaimStarted } from '@/utils/helpers'
import { fallback, http } from 'viem'
import {
  arbitrum,
  arbitrumSepolia,
  avalanche,
  avalancheFuji,
  base,
  baseSepolia,
  bsc,
  bscTestnet,
  mainnet,
  optimism,
  optimismSepolia,
  polygon,
  polygonAmoy,
  sepolia
} from 'viem/chains'

export const transportsTest = hasClaimStarted()
  ? {
      [bscTestnet.id]: fallback(
        [
          http('https://bsc-testnet-rpc.publicnode.com'),
          http('https://data-seed-prebsc-1-s1.bnbchain.org:8545'),
          http('https://endpoints.omniatech.io/v1/bsc/testnet/public'),
          http('https://rpc.ankr.com/bsc_testnet_chapel'),
          http(
            'https://maximum-necessary-county.bsc-testnet.quiknode.pro/1bee731e043e1f6950e81dbc1e42db386e474ccc/'
          )
        ],
        // { rank: { interval: 60_000, sampleCount: 3 } }
      )
      // [arbitrumSepolia.id]: fallback([
      //   http('https://sepolia-rollup.arbitrum.io/rpc'),
      //   http('https://rpc.ankr.com/arbitrum_sepolia'),
      //   http('https://endpoints.omniatech.io/v1/arbitrum/sepolia/public'),
      //   http(
      //     'https://damp-snowy-patina.arbitrum-sepolia.quiknode.pro/0085e31f37f8ee0b023c462c0b0cc567e1f1b6d0/'
      //   ),
      // ])
    }
  : {
      [bscTestnet.id]: fallback(
        [
          http('https://bsc-testnet-rpc.publicnode.com'),
          http('https://data-seed-prebsc-1-s1.bnbchain.org:8545'),
          http('https://endpoints.omniatech.io/v1/bsc/testnet/public'),
          http('https://rpc.ankr.com/bsc_testnet_chapel'),
          http(
            'https://maximum-necessary-county.bsc-testnet.quiknode.pro/1bee731e043e1f6950e81dbc1e42db386e474ccc/'
          )
        ],
        // { rank: { interval: 60_000, sampleCount: 3 } }
      ),
      [polygonAmoy.id]: fallback(
        [
          http('https://rpc-amoy.polygon.technology'),
          http('https://polygon-amoy.drpc.org'),
          http('https://rpc.ankr.com/polygon_amoy'),
          http(
            'https://distinguished-twilight-violet.matic-amoy.quiknode.pro/64efc63f7079ad7628e958b31d1c0e53655f18a0/'
          ),
          http('https://polygon-amoy-bor-rpc.publicnode.com')
        ],
        // { rank: { interval: 60_000, sampleCount: 3 } }
      ),
      [arbitrumSepolia.id]: fallback(
        [
          http('https://sepolia-rollup.arbitrum.io/rpc'),
          http('https://rpc.ankr.com/arbitrum_sepolia'),
          http('https://endpoints.omniatech.io/v1/arbitrum/sepolia/public'),
          http(
            'https://damp-snowy-patina.arbitrum-sepolia.quiknode.pro/0085e31f37f8ee0b023c462c0b0cc567e1f1b6d0/'
          )
        ],
        // { rank: { interval: 60_000, sampleCount: 3 } }
      ),
      [sepolia.id]: fallback(
        [
          http('https://ethereum-sepolia-rpc.publicnode.com'),
          http('https://ethereum-sepolia.rpc.subquery.network/public'),
          http('https://rpc.ankr.com/eth_sepolia'),
          http('https://endpoints.omniatech.io/v1/eth/sepolia/public'),
          http(
            'https://smart-polished-telescope.ethereum-sepolia.quiknode.pro/17fb0830683790166f9fbe975358635327694497/'
          )
        ],
        // { rank: { interval: 60_000, sampleCount: 3 } }
      ),
      [optimismSepolia.id]: fallback(
        [
          http('https://sepolia.optimism.io'),
          http('https://endpoints.omniatech.io/v1/op/sepolia/public'),
          http('https://rpc.ankr.com/optimism_sepolia'),
          http(
            'https://responsive-silent-cloud.optimism-sepolia.quiknode.pro/0bdbcf914bc876f4cbec85c1c05268de7889271f/'
          )
        ],
        // { rank: { interval: 60_000, sampleCount: 3 } }
      ),
      [baseSepolia.id]: fallback(
        [
          http('https://sepolia.base.org'),
          http('https://base-sepolia-rpc.publicnode.com'),
          http('https://rpc.ankr.com/base_sepolia'),
          http(
            'https://little-radial-fog.base-sepolia.quiknode.pro/e425d8e69773eb8e5d66d94e366bb7dff63f4584/'
          )
        ],
        // { rank: { interval: 60_000, sampleCount: 3 } }
      ),
      [avalancheFuji.id]: fallback([
        http('https://avalanche-fuji-c-chain-rpc.publicnode.com'),
        http('https://rpc.ankr.com/avalanche_fuji-c'),
        http('https://endpoints.omniatech.io/v1/avax/fuji/public'),
        http(
          'https://bold-green-rain.avalanche-testnet.quiknode.pro/39dd685b505b307f7b6cf7da5b45fd587ed77f37/ext/bc/C/rpc/'
        )
      ])
    }

export const transportsMain = hasClaimStarted()
  ? {
      [bsc.id]: http()
    }
  : {
      [bsc.id]: http(),
      [mainnet.id]: http(),
      [polygon.id]: http(),
      [arbitrum.id]: http(),
      [optimism.id]: http(),
      [base.id]: http(),
      [avalanche.id]: http()
    }
