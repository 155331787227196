<script setup>
import Widget from '@/components/presale-widget/Widget.vue';
import { advertisers, advertisersCrypto, blockchainCoinDetails, coinsUp, coinsDown } from '../assets/data/index';
import AdvertisersMarquee from '../components/AdvertisersMarquee.vue';
import DetailsCard from '../components/base/DetailsCard.vue';
import OutlinedBtn from '../components/base/OutlinedBtn.vue';
import SecondaryBtn from '../components/base/SecondaryBtn.vue';
import IconDetailCard from '../components/base/IconDetailCard.vue';
import Title from '../components/base/Title.vue';
import { useChangelly } from '@/composables/changelly';
import CoinsMarquee from '../components/CoinsMarquee.vue';
import { Vue3Lottie } from 'vue3-lottie'
import Hero2 from '@/assets/hero-2.json'

const { changellyUrl, setChangellyUrl } = useChangelly();

const paymentsDetails = [
    'Simplified wallet management;',
    'Broad wallet compatibility;',
    'Enhanced user accessibility;',
]

</script>

<template>
    <main>
        <section>
            <div class="radial-background-left-mobile"></div>
            <div class="radial-background-right-mobile"></div>
            <CoinsMarquee class="image-mobile" :data="coinsUp" :direction="'reverse'" />

            <div class="hero-2">
                <vue3-lottie class="lottie-image" :animation-data="Hero2" :loop="true" :autoplay="true" />
                <div class="hero-2-content ">
                    <div class="min-vh-100 d-flex" style="flex-direction: column;justify-content: center; gap: 70px;"
                        data-aos="fade-up">
                        <div class="container">
                            <div class="row  align-items-center justify-content-center text-center">
                                <div class="col-md-10">
                                    <OutlinedBtn>
                                        <template #title>
                                            <div class="d-flex align-items-center gap-1">

                                                Join Us Free World
                                                <img src="/images/right-arrow.svg" alt="Right arrow" width="16"
                                                    height="16" />
                                            </div>
                                        </template>
                                    </OutlinedBtn>
                                    <h1 class="display-2 text-white fw-bold my-4">
                                        <Title>
                                            <span class="text-white">WEB 3.0</span>
                                            <br />
                                            <template #after>PRESALE</template>
                                        </Title>
                                    </h1>
                                    <p class="text-secondary-light mx-auto" style="max-width: 70%;">MintWeb's Crypto
                                        Our
                                        Toolkit simplifies launches with automated features, security, and customizable
                                        solutions for
                                        successful
                                        presales.
                                    </p>
                                    <div class="mt-4">
                                        <SecondaryBtn title="Discover Those Worlds" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CoinsMarquee class="image-mobile" :data="coinsDown" />
        </section>

        <AdvertisersMarquee :dark="true" :borders="true" :data="advertisers" />

        <div>
            <DetailsCard :gradient="true">
                <template #header>
                    <h1 class="display-5 fw-bold ">
                        <Title>
                            Web3-Powered<br /><template #after>Payments</template>
                        </Title>
                    </h1>
                </template>

                <template #body>
                    <div class="fs-6 text-secondary-dark mt-4">
                        <p>
                            MintWeb’s Web3 Payment Widget is a powerful tool designed to streamline presale processes
                            for token projects. With full multi-currency support, users can easily pay in a variety of
                            cryptocurrencies, expanding your project’s reach to a global audience. The integrated swap
                            functionality allows investors to seamlessly convert assets, making it easier to participate
                            in the presale, regardless of their token holdings.
                        </p>
                        <p>
                            Our solution also includes built-in affiliate marketing features, enabling projects to
                            reward users for driving traffic and bringing in new participants. This incentivizes
                            community growth and engagement while boosting presale success. The widget gives projects
                            full control over presale rounds, allowing for dynamic adjustments and offering a
                            customizable experience to suit different fundraising strategies.
                        </p>
                        <p>
                            Additionally, MintWeb's Web3 Payment Widget supports card payments, removing the barriers
                            for non-crypto natives to participate in token sales. By simplifying the payment process,
                            you ensure that anyone can join, regardless of their experience with cryptocurrencies.
                        </p>
                        <p>
                            With robust security, seamless integration, and user-friendly features, MintWeb’s Web3
                            Payment Widget is the all-in-one solution to optimize your presale, increase accessibility,
                            and drive project growth. It’s the perfect tool for any Web3 initiative looking to maximize
                            efficiency and inclusivity.
                        </p>

                    </div>
                </template>

                <template #image>
                    <Widget />
                </template>
            </DetailsCard>
        </div>

        <div class="position-relative">
            <div class="payments-background-right"> </div>
            <div class="payments-overlay"></div>
            <div class="star" style="left: 67%;top: 20px;" data-aos="fade-up">
                <img src="/images/star.svg" alt="Star" width="24" height="24" />
            </div>
            <div class="star" style="right: 0;top: 20%;" data-aos="fade-up">
                <img src="/images/star.svg" alt="Star" width="40" height="40" />
            </div>
            <div class="star" style="left: 63%;bottom: 20%;" data-aos="fade-up">
                <img src="/images/star.svg" alt="Star" width="40" height="40" />
            </div>
            <div class="container py-container">
                <div class="row gx-lg-5 gy-5 align-items-center justify-content-center">
                    <!-- Left Section: Spanning 3 Columns -->
                    <div class="col-12 col-md-5 d-flex flex-column gap-1" data-aos="fade-up">

                        <h1 class="display-5 fw-bold text-white">
                            <Title>
                                <!-- Multi-Wallet <br><template #after>Integration</template> -->
                                <template #before>Multi-Wallet</template><br>Integration
                            </Title>
                        </h1>

                        <div class="fs-6 text-secondary-light mt-4">
                            MintWeb’s multi-wallet integration offers users the flexibility to connect and manage
                            multiple
                            wallets during presales, including popular options like MetaMask, Trust Wallet, and others.
                            This
                            seamless integration ensures that participants can easily switch between wallets,
                            streamlining the
                            transaction process. With this feature, we enhance accessibility for a broader audience,
                            making it
                            easier for both crypto-savvy users and newcomers to engage in presales.
                        </div>

                        <div class="fw-bold fs-4 mt-3 text-white">
                            Key Benefits
                        </div>

                        <div v-for="(item, idx) in paymentsDetails" :key="idx"
                            class="d-flex align-items-center gap-2 mt-2 ">
                            <img src="/images/bullet.svg" alt="Bullet" width="14" height="14" />
                            <div class="text-secondary-light fs-6">{{ item }}</div>
                        </div>

                    </div>
                    <div class="col-12 col-md-2"></div>

                    <!-- Right Section: Spanning 2 Columns -->
                    <div class="col-12 col-md-5">
                        <div class="text-center">
                            <img style="width: 100%;" src="/images/payments-main.svg" alt="Payments" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <DetailsCard :gradient="true">
                <template #header>
                    <h1 class="display-5 fw-bold ">
                        <Title>
                            Coin Launch<br><template #after>Toolkit</template>
                        </Title>
                    </h1>
                </template>

                <template #body>
                    <div class="fs-6 text-secondary-dark mt-4">
                        <p>
                            MintWeb’s Coin Launch Toolkit is specially designed for launching meme coins, Real-World
                            Asset (RWA)-backed tokens, and more. It simplifies token creation, presale management, and
                            smart contract deployment with tailored features to suit unique use cases. Whether you're
                            launching a fun meme coin or a serious RWA-backed token, our toolkit ensures secure,
                            efficient execution.
                        </p>
                        <p>
                            With options for distribution, investor tracking, and
                            scalability, MintWeb helps you bring your vision to life while maintaining transparency and
                            security.
                        </p>
                    </div>
                </template>

                <template #image>
                    <div class="row align-items-center g-4">
                        <div class="col-6" v-for="(item, idx) in blockchainCoinDetails" :key="idx">
                            <IconDetailCard :data="item" />
                        </div>
                    </div>
                </template>
            </DetailsCard>
        </div>

        <!-- <div class="container py-container">
            <div class="text-white display-5 fw-bold text-center" data-aos="fade-up">
                <Title>
                    Let’s Discover
                    <template #after>
                        Our Solutions
                    </template>
                </Title>
            </div>
            <div class="py-container">
                <DetailsList :secondary="true" :data="blockChainServices" />
            </div>
        </div> -->

        <div>
            <AdvertisersMarquee :dark="true" :borders="true" title="Multi Network" :data="advertisersCrypto" />
        </div>

        <div class="modal fade" id="exchangeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="exchangeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="d-flex w-100 justify-content-between mb-3">
                            <div></div>
                            <h1 class="modal-title fs-5" id="exchangeModalLabel">
                                {{ $t('swap.buyOrSwapTokens') }}
                            </h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                @click="setChangellyUrl('')"></button>
                        </div>

                        <iframe width="100%" height="400px" frameborder="none" allow="camera" :src="changellyUrl">Can't
                            load
                            widget</iframe>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<style scoped>
.payments-background-right {
    position: absolute;
    top: 50%;
    right: 0%;
    width: 100%;
    height: 100%;
    background: radial-gradient(40% 50% at 100% 50%, #25DA4A 0%, rgba(255, 255, 255, 0) 100%);
    transform: translateY(-50%);
    z-index: 1;
    pointer-events: none;
}

.payments-overlay {
    position: absolute;
    top: 0;
    right: 0%;
    width: 30%;
    margin-left: auto;
    height: 100%;
    background: url('/images/footer-overlay.webp') center/cover no-repeat;
    opacity: 0.1;
    /* Overlay opacity */
    z-index: 1;
}

.star {
    position: absolute;
    z-index: 3;
}
</style>