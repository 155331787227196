<script setup>
const props = defineProps(['data'])
</script>
<template>
    <div class="py-3 px-2 icon-card d-flex align-items-center gap-2">
        <div class="icon-container">
            <img :src="props.data.icon" alt="Icon" width="24" height="24" />
        </div>

        <div class="fw-light fs-6">{{ data.title }}</div>
    </div>
</template>

<style scoped>
.icon-card {
    background: #EAEBED;
    border-radius: 8px;
    width: 100%;

}

.icon-container {
    background: white;
    padding: 10px;
    border-radius: 8px;
}
</style>