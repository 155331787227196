import {
  mainnet,
  bsc,
  polygon,
  arbitrum,
  optimism,
  base,
  avalanche,
  bscTestnet,
  sepolia,
  polygonAmoy,
  arbitrumSepolia,
  optimismSepolia,
  avalancheFuji,
  baseSepolia
} from 'viem/chains'
import { usdtAbi } from './contracts_abi'
import { TokenType } from './enums'

export const mainnetTokens = [
  {
    network: bsc,
    tokens: [
      {
        id: 1,
        name: 'Binance Coin',
        symbol: 'BNB',
        network: bsc,
        contract: '',
        decimals: 18
      },
      {
        id: 2,
        name: 'USD Tether',
        symbol: 'USDT',
        network: bsc,
        contract: '0x55d398326f99059fF775485246999027B3197955',
        decimals: 18
      },
      {
        id: 3,
        name: 'USD Coin',
        symbol: 'USDC',
        network: bsc,
        contract: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
        decimals: 18
      }
    ]
  },
  {
    network: mainnet,
    tokens: [
      {
        id: 4,
        name: 'Ethereum',
        symbol: 'ETH',
        network: mainnet,
        contract: '',
        decimals: 18
      },
      {
        id: 5,
        name: 'USD Tether',
        symbol: 'USDT',
        network: mainnet,
        contract: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        decimals: 6
      },
      {
        id: 6,
        name: 'USD Coin',
        symbol: 'USDC',
        network: mainnet,
        contract: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        decimals: 6
      }
    ]
  },
  {
    network: polygon,
    tokens: [
      {
        id: 7,
        name: 'Polygon',
        symbol: 'POL',
        network: polygon,
        contract: '',
        decimals: 18
      },
      {
        id: 8,
        name: 'USD Tether',
        symbol: 'USDT',
        network: polygon,
        contract: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
        decimals: 6
      },
      {
        id: 9,
        name: 'USD Coin',
        symbol: 'USDC',
        network: polygon,
        contract: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
        decimals: 6
      }
    ]
  },
  {
    network: arbitrum,
    tokens: [
      {
        id: 10,
        name: 'Ethereum',
        symbol: 'ETH',
        network: arbitrum,
        contract: '',
        decimals: 18
      },
      {
        id: 11,
        name: 'USD Tether',
        symbol: 'USDT',
        network: arbitrum,
        contract: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
        decimals: 6
      },
      {
        id: 12,
        name: 'USD Coin',
        symbol: 'USDC',
        network: arbitrum,
        contract: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
        decimals: 6
      }
    ]
  },
  {
    network: optimism,
    tokens: [
      {
        id: 13,
        name: 'Ethereum',
        symbol: 'ETH',
        network: optimism,
        contract: '',
        decimals: 18
      },
      {
        id: 14,
        name: 'USD Tether',
        symbol: 'USDT',
        network: optimism,
        contract: '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58',
        decimals: 6
      },
      {
        id: 15,
        name: 'USD Coin',
        symbol: 'USDC',
        network: optimism,
        contract: '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
        decimals: 6
      }
    ]
  },
  {
    network: avalanche,
    tokens: [
      {
        id: 16,
        name: 'Avalanche',
        symbol: 'AVAX',
        network: avalanche,
        contract: '',
        decimals: 18
      },
      {
        id: 17,
        name: 'USD Tether',
        symbol: 'USDT',
        network: avalanche,
        contract: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
        decimals: 6
      },
      {
        id: 18,
        name: 'USD Coin',
        symbol: 'USDC',
        network: avalanche,
        contract: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
        decimals: 6
      }
    ]
  },
  {
    network: base,
    tokens: [
      {
        id: 19,
        name: 'Ethereum',
        symbol: 'ETH',
        network: base,
        contract: '',
        decimals: 18
      },
      {
        id: 20,
        name: 'USD Coin',
        symbol: 'USDC',
        network: base,
        contract: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
        decimals: 6
      }
    ]
  }
]

export const tokens = [
  {
    id: 1,
    name: 'Binance Coin',
    symbol: 'BNB',
    networkId: bscTestnet.id,
    type: TokenType.Main,
    contract: '',
    decimals: 18,
    abi: null,
    enabled: true
  },
  {
    id: 2,
    name: 'USD Tether',
    symbol: 'USDT',
    networkId: bscTestnet.id,
    type: TokenType.Token,
    contract: '0xCAe491caE81ea13554B39DcaD97c01Bb75230Dc8',
    decimals: 6,
    abi: usdtAbi,
    enabled: true
  },
  {
    id: 21,
    name: 'Card',
    symbol: 'USD',
    networkId: bscTestnet.id,
    wert_commodity: 'BNB',
    wert_network: 'bsc',
    type: TokenType.Card,
    contract: '',
    decimals: 6,
    abi: usdtAbi,
    enabled: true
  },
  {
    id: 3,
    name: 'Ethereum',
    symbol: 'ETH',
    networkId: sepolia.id,
    type: TokenType.Main,
    contract: '',
    decimals: 18,
    abi: null,
    enabled: true
  },
  {
    id: 4,
    name: 'USD Tether',
    symbol: 'USDT',
    networkId: sepolia.id,
    type: TokenType.Token,
    contract: '0xCAe491caE81ea13554B39DcaD97c01Bb75230Dc8',
    decimals: 6,
    abi: usdtAbi,
    enabled: true
  },
  {
    id: 22,
    name: 'Card',
    symbol: 'USD',
    networkId: sepolia.id,
    wert_commodity: 'ETH',
    wert_network: 'sepolia',
    type: TokenType.Card,
    contract: '',
    decimals: 6,
    abi: usdtAbi,
    enabled: false
  },
  {
    id: 5,
    name: 'Polygon',
    symbol: 'POL',
    networkId: polygonAmoy.id,
    type: TokenType.Main,
    contract: '',
    decimals: 18,
    abi: null,
    enabled: true
  },
  {
    id: 6,
    name: 'USD Tether',
    symbol: 'USDT',
    networkId: polygonAmoy.id,
    type: TokenType.Token,
    contract: '0xCAe491caE81ea13554B39DcaD97c01Bb75230Dc8',
    decimals: 6,
    abi: usdtAbi,
    enabled: true
  },
  {
    id: 23,
    name: 'Card',
    symbol: 'USD',
    networkId: polygonAmoy.id,
    wert_commodity: 'POL',
    wert_network: 'amoy',
    type: TokenType.Card,
    contract: '',
    decimals: 6,
    abi: usdtAbi,
    enabled: false
  },
  {
    id: 7,
    name: 'Ethereum',
    symbol: 'ETH',
    networkId: arbitrumSepolia.id,
    type: TokenType.Main,
    contract: '',
    decimals: 18,
    abi: null,
    enabled: true
  },
  {
    id: 8,
    name: 'USD Tether',
    symbol: 'USDT',
    networkId: arbitrumSepolia.id,
    type: TokenType.Token,
    contract: '0xCAe491caE81ea13554B39DcaD97c01Bb75230Dc8',
    decimals: 6,
    abi: usdtAbi,
    enabled: true
  },
  {
    id: 24,
    name: 'Card',
    symbol: 'USD',
    networkId: arbitrumSepolia.id,
    wert_commodity: 'ETH',
    wert_network: 'arbitrum_sepolia',
    type: TokenType.Card,
    contract: '',
    decimals: 6,
    abi: usdtAbi,
    enabled: false
  },
  {
    id: 9,
    name: 'Ethereum',
    symbol: 'ETH',
    networkId: optimismSepolia.id,
    type: TokenType.Main,
    contract: '',
    decimals: 18,
    abi: null,
    enabled: true
  },
  {
    id: 10,
    name: 'USD Tether',
    symbol: 'USDT',
    networkId: optimismSepolia.id,
    type: TokenType.Token,
    contract: '0xCAe491caE81ea13554B39DcaD97c01Bb75230Dc8',
    decimals: 6,
    abi: usdtAbi,
    enabled: true
  },
  {
    id: 11,
    name: 'Avalanche',
    symbol: 'AVAX',
    networkId: avalancheFuji.id,
    type: TokenType.Main,
    contract: '',
    decimals: 18,
    abi: null,
    enabled: true
  },
  {
    id: 12,
    name: 'USD Tether',
    symbol: 'USDT',
    networkId: avalancheFuji.id,
    type: TokenType.Token,
    contract: '0x6F03edA5e8664FD8E50D2DBDA277f3b5bc8b7edf',
    decimals: 6,
    abi: usdtAbi,
    enabled: true
  },
  {
    id: 13,
    name: 'Ethereum',
    symbol: 'ETH',
    networkId: baseSepolia.id,
    type: TokenType.Main,
    contract: '',
    decimals: 18,
    abi: null,
    enabled: true
  },
  {
    id: 14,
    name: 'USD Tether',
    symbol: 'USDT',
    networkId: baseSepolia.id,
    type: TokenType.Token,
    contract: '0xCAe491caE81ea13554B39DcaD97c01Bb75230Dc8',
    decimals: 6,
    abi: usdtAbi,
    enabled: true
  },
  {
    id: 25,
    name: 'Card',
    symbol: 'USD',
    networkId: baseSepolia.id,
    wert_commodity: 'ETH',
    wert_network: 'base_sepolia',
    type: TokenType.Card,
    contract: '',
    decimals: 6,
    abi: usdtAbi,
    enabled: false
  }
]
