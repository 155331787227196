<script setup>
import { formatEther } from 'viem'
import { computed, ref, watch } from 'vue'
import { useAccount } from '@/composables/account'
import { useContract } from '@/composables/contract'
import { useChain } from '@/composables/chain'
import { useData } from '@/composables/data'
import { useToken } from '@/composables/token'
import { TokenType, WriteContractStatus } from '@/utils/constants/enums'
import { formatNumber, handleKeyDown, openModal } from '@/utils/helpers'

import { useI18n } from 'vue-i18n'
import { useChangelly } from '@/composables/changelly'

const { locale } = useI18n()
const { account, accountBalances } = useAccount()
const { selectedChain } = useChain()
const { selectedToken } = useToken()
const {
  tokenPriceMain,
  tokenPriceUSD,
  currentRoundDetails,
  roundPrices,
  remainingTokens,
  maxAmountToBuy,
  minAmountToBuy
} = useData()
const { isLoading, transactionHash, status, buy, clearState } = useContract()
const { setChangellyUrl } = useChangelly();

const tokenSymbol = import.meta.env.VITE_PRESALE_TOKEN_SYMBOL
const changellyDefaultToken = import.meta.env.VITE_CHANGELLY_DEFAULT_TOKEN
const amountPay = ref('')
const amountReceive = ref('')
const onlyBuy = ref(false)

const accountBalance = computed(() => {
  return accountBalances.value.get(selectedToken.value.id)?.formatted ?? 0
})

const hasAmount = computed(() => {
  return amountPay.value !== '' && Number(amountPay.value) > 0
})

const lessThanMinimum = computed(() => {
  return amountReceive.value !== '' && Number(amountReceive.value) < Number(minAmountToBuy.value)
})

const exceedsMaxAmount = computed(() => {
  return Number(amountReceive.value) > Number(maxAmountToBuy.value)
})

const hasEnoughBalance = computed(() => {
  return (
    Number(accountBalance.value) >= Number(amountPay.value) ||
    selectedToken.value.type === TokenType.Card
  )
})

function calcAmountPay(value) {
  if (value === '') {
    amountPay.value = ''
    return
  }

  // Sanitize pasted values
  if (/[^0-9]/g.test(value)) {
    amountReceive.value = ''
    amountPay.value = ''
    return
  }

  const amount = Number(value)
  const diff = Number(remainingTokens.value)
  const priceInUSD = Number(formatEther(tokenPriceUSD.value))
  const priceInMain = Number(formatEther(tokenPriceMain.value))
  const nextPriceInUSD = Number(
    Number(currentRoundDetails.value.currentRoundNumber + 1n) === roundPrices.value.length
      ? formatEther(roundPrices.value[currentRoundDetails.value.currentRoundNumber])
      : formatEther(roundPrices.value[currentRoundDetails.value.currentRoundNumber + 1n])
  )

  if (selectedToken.value.type !== TokenType.Main) {
    if (amount > diff) {
      amountPay.value = (
        Math.ceil(diff * priceInUSD) + Math.ceil((amount - diff) * nextPriceInUSD)
      ).toFixed(2)
      return
    }

    amountPay.value = (amount * priceInUSD).toFixed(2)
    return
  }

  if (amount > diff) {
    const nextPriceInMain = (nextPriceInUSD * priceInMain) / priceInUSD

    amountPay.value = (diff * priceInMain + (amount - diff) * nextPriceInMain).toFixed(8)
    return
  }

  amountPay.value = (amount * priceInMain * 1.03).toFixed(8)
}

function calcAmountReceive(value) {
  if (value === '') {
    amountReceive.value = ''
    return
  }

  // Sanitize pasted values
  if (/[^0-9.]/g.test(value) || value.split('.').length > 2) {
    amountReceive.value = ''
    amountPay.value = ''
    return
  }

  const amount = Number(value)
  const priceInUSD = Number(formatEther(tokenPriceUSD.value))
  const priceInMain = Number(formatEther(tokenPriceMain.value))
  const nextPriceInUSD = Number(
    Number(currentRoundDetails.value.currentRoundNumber + 1n) === roundPrices.value.length
      ? formatEther(roundPrices.value[currentRoundDetails.value.currentRoundNumber])
      : formatEther(roundPrices.value[currentRoundDetails.value.currentRoundNumber + 1n])
  )
  const diff = Number(remainingTokens.value)
  const diffInUSD = diff * priceInUSD
  const diffInMain = diff * priceInMain

  if (selectedToken.value.type !== TokenType.Main) {
    if (amount > diffInUSD) {
      amountReceive.value = (diff + Math.ceil((amount - diffInUSD) / nextPriceInUSD)).toFixed(0)
      return
    }

    amountReceive.value = Math.ceil(amount / priceInUSD).toFixed(0)
    return
  }

  if (amount > diffInMain) {
    const nextPriceInMain = (nextPriceInUSD * priceInMain) / priceInUSD

    amountReceive.value = (diff + (amount - diffInMain) / nextPriceInMain).toFixed(0)
    return
  }

  amountReceive.value = Math.ceil((amount / priceInMain) * 0.97).toFixed(0)
}

watch([selectedToken, tokenPriceMain, tokenPriceUSD], () => {
  calcAmountPay(amountReceive.value)
})

function maxAmount() {
  if (selectedToken.value.type === TokenType.Card) return

  const decimals = selectedToken.value.type === TokenType.Token ? 3 : 9
  amountPay.value = Number(accountBalance.value).toFixed(decimals)
  amountPay.value = amountPay.value.substring(0, amountPay.value.length - 1)
  calcAmountReceive(amountPay.value)
}

function openTransaction() {
  const url = selectedChain.value.blockExplorers.default.url + '/tx/' + transactionHash.value
  window.open(url, '_blank')
}
</script>

<template>
  <div class="mt-3 w-100">
    <div v-if="account.isConnected && selectedToken.type !== TokenType.Card"
      class="font-14 fw-bold text-center pb-2 hr-lines">
      {{ $t('swap.balance') }}:
      {{ formatNumber(accountBalance, 2, 8) }}
    </div>

    <div v-if="!isLoading && status === ''">
      <div class="row my-2">
        <div class="col-md-6 pe-md-2">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <label for="amount-pay" class="d-block font-13">
              {{ $t('swap.youPay', { token: selectedToken.symbol }) }}
            </label>
            <span class="font-13 cursor-pointer" @click="maxAmount">MAX</span>
          </div>
          <div class="input-group d-flex align-items-start">
            <input type="text" v-model="amountPay" id="amount-pay" @input="calcAmountReceive($event.target.value)"
              @keydown="handleKeyDown($event, true)" placeholder="0" class="form-control text-truncate" />
            <span class="input-group-text">
              <img :src="`/coins-icons/${selectedToken.symbol}.svg`" :alt="`${selectedToken.symbol} logo`" width="24"
                height="24" />
            </span>
          </div>
        </div>

        <div class="col-md-6 ps-md-2 mt-3 mt-md-0">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <label for="amount-receive" class="d-block font-13">
              {{ $t('swap.youReceive', { token: tokenSymbol }) }}
            </label>
          </div>
          <div class="input-group d-flex align-items-start">
            <input type="text" v-model="amountReceive" id="amount-receive" @input="calcAmountPay($event.target.value)"
              @keydown="handleKeyDown($event, false)" placeholder="0" class="form-control text-truncate" />
            <span class="input-group-text">
              <img :src="`/coins-icons/${tokenSymbol}.svg`" :alt="`${tokenSymbol} logo`" width="24"
                height="24" />
            </span>
          </div>
        </div>
      </div>

      <div class="text-center">
        <p v-if="exceedsMaxAmount" class="text-danger font-13">
          {{ $t('swap.exceedsMaxError', { token: tokenSymbol, max: maxAmountToBuy }) }}
        </p>
        <p v-else-if="lessThanMinimum" class="text-danger font-13">
          {{
            $t('swap.lessThanMinimumError', { token: tokenSymbol, min: minAmountToBuy.toFixed(0) })
          }}
        </p>
        <p v-else-if="!hasEnoughBalance" class="text-danger font-13">
          {{ $t('swap.notEnoughBalanceError') }}
        </p>
      </div>

      <div class="d-grid w-100 align-items-center mt-3">
        <button v-if="!account.isConnected" type="button" class="btn btn-primary" @click="openModal">
          {{ $t('connect') }}
        </button>
        <div v-else class="d-flex flex-column w-100 gap-2">
          <div class="d-flex flex-column flex-md-row w-100 gap-2">
            <button type="button" class="btn btn-primary w-100" :class="[
              !hasAmount || exceedsMaxAmount || lessThanMinimum || !hasEnoughBalance
                ? 'disabled'
                : ''
            ]" @click="() => {
                  if (!hasAmount || exceedsMaxAmount || lessThanMinimum || !hasEnoughBalance) return
                  buy(amountReceive, amountPay, true)
                }
                ">
              {{ $t('buyAndStake') }}
            </button>
            <button type="button" class="btn btn-primary w-100" :class="[
              !hasAmount || exceedsMaxAmount || lessThanMinimum || !hasEnoughBalance
                ? 'disabled'
                : ''
            ]" @click="() => {
                  if (!hasAmount || exceedsMaxAmount || lessThanMinimum || !hasEnoughBalance) return
                  onlyBuy = true
                  buy(amountReceive, amountPay, false)
                }
                ">
              {{ $t('buy') }}
            </button>
          </div>

          <div v-if="selectedToken.type !== TokenType.Card"
            class="d-flex w-100 justify-content-center align-items-center my-1">
            <p class="font-13 mb-0 text-center">
              <a href="" class="text-decoration-underline" data-bs-toggle="modal" data-bs-target="#exchangeModal"
                @click="setChangellyUrl(`https://widget.changelly.com?from=*&to=${changellyDefaultToken.toLowerCase()}&amount=1&address=${account.address}&fromDefault=btc&toDefault=${changellyDefaultToken.toLowerCase()}&merchant_id=7Qc-tOqjQ1dqNfP4&payment_id=&v=3`)">
                {{ $t('swap.notEnoughTokens', { token: selectedToken.symbol }) }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="d-flex flex-column w-100 align-items-center justify-content-center gap-2 mt-4">
      <div v-if="isLoading" class="spinner-border text-primary m-2">
        <span class="visually-hidden">{{ $t('loading') }}</span>
      </div>

      <img v-if="status === WriteContractStatus.Success" src="@/assets/icons/success.svg" alt="Success icon"
        width="55" height="55" />
      <img v-else-if="status === WriteContractStatus.Error" src="@/assets/icons/error.svg" alt="Error icon" width="55"
        height="55" />

      <div v-if="status !== ''" class="fw-normal fs-6 mt-1 text-uppercase text-center" :class="status === WriteContractStatus.Error
          ? 'text-danger'
          : status === WriteContractStatus.Success
            ? 'text-success'
            : 'text-secondary'
        ">
        {{ $t('status.' + status.description) }}
      </div>

      <p v-if="status === WriteContractStatus.NeedApproval" class="font-14 mb-3 text-black">
        {{
          $t('swap.needApprovalDescription', { token: tokenSymbol, usdToken: selectedToken.symbol })
        }}
      </p>

      <p v-if="status === WriteContractStatus.WaitingApproval" class="font-14 mb-3 text-black">
        {{ $t('swap.waitingApprovalDescription') }}
      </p>

      <p v-if="status === WriteContractStatus.NeedConfirmation" class="font-14 mb-3 text-black">
        {{ $t('swap.needConfirmationDescription', { token: tokenSymbol }) }}
      </p>

      <p v-if="status === WriteContractStatus.WaitingTransaction" class="font-14 mb-3 text-black">
        {{ $t('swap.waitingTransactionDescription', { amount: amountReceive }) }}
      </p>

      <p v-if="status === WriteContractStatus.OpeningWertModal" class="font-14 mb-3 text-black">
        {{ $t('swap.openingWertModal') }}
      </p>

      <p v-if="status === WriteContractStatus.WaitingWertPayment" class="font-14 mb-3 text-black">
        {{ $t('swap.waitingWertPayment', { token: tokenSymbol }) }}
      </p>

      <p v-if="status === WriteContractStatus.WaitingWertConfirmation" class="font-14 mb-3 text-black">
        {{ $t('swap.waitingWertConfirmation', { amount: amountReceive }) }}
      </p>

      <div v-if="status === WriteContractStatus.Success || status === WriteContractStatus.Error" class="w-100">
        <p v-if="status === WriteContractStatus.Success" class="font-14 mb-3 text-success">
          {{ $t('swap.successDescription', { amount: amountReceive }) }}
        </p>
        <p v-else-if="transactionHash" class="font-14 mb-3 text-danger">
          {{ $t('swap.confirmationError') }}
        </p>
        <p v-else class="font-14 mb-3 text-danger">{{ $t('swap.errorDescription') }}</p>

        <RouterLink v-if="onlyBuy && transactionHash" :to="{ path: `/${locale}/staking` }"
          class="btn btn-primary w-100">
          {{ $t('stakeNow') }}
        </RouterLink>
        <div class="d-flex flex-column flex-lg-row w-100 gap-2 mt-2">
          <button v-if="transactionHash" type="button" class="btn btn-primary w-100" @click="openTransaction">
            {{ $t('viewTransaction') }}
          </button>
          <button type="button" class="btn btn-primary w-100" @click="() => {
              onlyBuy = false
              if (status === WriteContractStatus.Success) {
                amountPay = ''
                amountReceive = ''
              }
              clearState()
            }
            ">
            {{ $t('swap.startAgain') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
