import { tokens } from '@/utils/constants/tokens'
import { computed, onMounted, ref, watch } from 'vue'
import { toValue } from 'vue'
import { useChain } from './chain'

const selectedToken = ref()
let unwatch

export function useToken() {
  const { selectedChain } = useChain()

  //Refresh token list when selected chain changes
  const tokenList = computed(() => {
    return tokens.filter((token) => token.enabled && token.networkId === toValue(selectedChain).id)
  })

  //Set selected token when it is no initialized
  if (!selectedToken.value) {
    selectedToken.value = tokenList.value.length ? tokenList.value[0] : undefined
  }

  //Refresh selected token when token list is updated
  onMounted(() => {
    if (!unwatch) {
      unwatch = watch(tokenList, () => {
        selectedToken.value = tokenList.value.length ? tokenList.value[0] : undefined
      })
    }
  })

  return { tokenList, selectedToken }
}
