<template>
  <div>
    <div class="d-flex align-items-center my-3 justify-content-center">
      <div v-if="borders" class="flex-grow-1 border-top"
        :class="props.dark ? 'border-secondary-light' : 'border-secondary-dark'">
      </div>
      <div class=" fw-semibold text-center mx-3" :class="props.dark ? 'text-secondary-light' : 'text-secondary-dark'">
        {{ title }}
      </div>
      <div v-if="borders" class="flex-grow-1 border-top"
        :class="props.dark ? 'border-secondary-light' : 'border-secondary-dark'">
      </div>
    </div>

    <section class="advertisers-section">
      <Vue3Marquee :pause-on-click="true" :duration="50">
        <div v-for="advertiser in data" :key="advertiser.id" class="px-5">
          <img :src="advertiser.logo" :alt="advertiser.name" width="170" height="40" class="advertiser-logo"
            :class="props.dark ? 'filter-logo' : ''">
        </div>
      </Vue3Marquee>
    </section>
  </div>
</template>

<script setup>
const props = defineProps({
  dark: {
    default: false,
    type: Boolean
  },
  title: {
    default: 'Platforms We Work With',
    type: String
  },
  borders: {
    default: false,
    type: Boolean
  },
  data: {
    default: [],
    type: Array
  }
})

</script>

<style scoped>
.advertisers-section {
  overflow: hidden;
}

.advertiser-logo {
  height: 40px;
  opacity: 0.5;
  transition: opacity 0.4s ease;
}

.filter-logo {
  filter: brightness(0) invert(1);
}

.advertiser-logo:hover {
  opacity: 1;
}
</style>