import { createI18n } from 'vue-i18n'
import { en } from './en'
import { pt } from './pt'
import { es } from './es'
import { fr } from './fr'
import { de } from './de'

export const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: en,
    pt: pt,
    es: es,
    fr: fr,
    de: de
  }
})
