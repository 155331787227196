import { chains, wagmiConfig } from '@/plugins/walletconnect'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { airdropAbi, presaleAbi, stakingAbi } from '@/utils/constants/contracts_abi'
import { toValue } from 'vue'
import { createPublicClient, formatEther, http, zeroAddress } from 'viem'
import { useChain } from './chain'
import { useAccount } from './account'
import { hasClaimStarted } from '@/utils/helpers'

class RoundDetails {
  roundTotal = 0n
  currentPrice = 0n
  roundFinalTime = 0n
  totalTokensSold = 0n
  currentRoundNumber = 0n
  amountRounds = 0n
  lastRoundTotal = 0n
  lastRoundFinalTime = 0n

  constructor(roundData) {
    if (roundData.length > 0) {
      this.roundTotal = roundData[0]
      this.currentPrice = roundData[1]
      this.roundFinalTime = roundData[2]
      this.totalTokensSold = roundData[3]
      this.currentRoundNumber = roundData[4]
      this.amountRounds = roundData[5]
      this.lastRoundTotal = roundData[6]
      this.lastRoundFinalTime = roundData[7]
    }
  }
}

class StakerDetails {
  amount = 0n
  stakedTime = 0n
  rewardsHarvested = 0n
  rewardsDebit = 0n
  rewardsLocked = 0n
  isBlacklisted = false

  constructor(roundData) {
    if (roundData.length > 0) {
      this.amount = roundData[0]
      this.stakedTime = roundData[1]
      this.rewardsHarvested = roundData[2]
      this.rewardsDebit = roundData[3]
      this.rewardsLocked = roundData[4]
      this.isBlacklisted = roundData[5]
    }
  }
}

const data = ref(new Map())
var rpcBlacklist = new Map()
let intervalId = null
let watching = null

export function useData() {
  const { selectedChain } = useChain()
  const { account } = useAccount()

  const dataSelectedNetwork = computed(() => {
    return data.value.get(toValue(selectedChain).id)
  })

  const totalTokensSold = computed(() => {
    let sum = 0n
    data.value.forEach((value) => {
      sum += value[0]
    })
    return sum
  })

  const usdRaised = computed(() => {
    let sum = 0n
    data.value.forEach((value) => {
      sum += value[1]
    })
    return sum
  })

  const usdRaisedTarget = computed(() => {
    let totalTokensSold = 0n
    data.value.forEach((value) => {
      totalTokensSold += value[3][3] ?? 0n
    })

    const currentRound = currentRoundDetails.value
    return (
      (currentRound.roundTotal - currentRound.lastRoundTotal - totalTokensSold) *
        currentRound.currentPrice +
      usdRaised.value
    )
  })

  const userTotalBalance = computed(() => {
    if (!toValue(account).isConnected) {
      return 0n
    }
    return userUnstakedBalance.value + stakerDetails.value.amount
  })

  const userUnstakedBalance = computed(() => {
    if (!toValue(account).isConnected) {
      return 0n
    }
    return dataSelectedNetwork.value ? dataSelectedNetwork.value[2] : 0n
  })

  const currentRoundDetails = computed(() => {
    return new RoundDetails(dataSelectedNetwork.value ? dataSelectedNetwork.value[3] : [])
  })

  const roundPrices = computed(() => {
    return dataSelectedNetwork.value ? dataSelectedNetwork.value[4] : []
  })

  const remainingTokens = computed(() => {
    return dataSelectedNetwork.value
      ? currentRoundDetails.value.roundTotal -
          (currentRoundDetails.value.totalTokensSold + currentRoundDetails.value.lastRoundTotal)
      : 0n
  })

  const maxAmountToBuy = computed(() => {
    return dataSelectedNetwork.value ? dataSelectedNetwork.value[5] : 0n
  })

  const minAmountToBuy = computed(() => {
    return dataSelectedNetwork.value
      ? Number(dataSelectedNetwork.value[6]) / Number(formatEther(tokenPriceUSD.value))
      : 0
  })

  const stakerDetails = computed(() => {
    if (!toValue(account).isConnected) {
      return new StakerDetails([])
    }
    return new StakerDetails(dataSelectedNetwork.value ? dataSelectedNetwork.value[7] : [])
  })

  const stakerRewards = computed(() => {
    return dataSelectedNetwork.value ? dataSelectedNetwork.value[8] : 0n
  })

  const rewardsPerDay = computed(() => {
    return dataSelectedNetwork.value ? dataSelectedNetwork.value[9] : 0n
  })

  const totalStaked = computed(() => {
    return dataSelectedNetwork.value ? dataSelectedNetwork.value[10] : 0n
  })

  const tokenPriceMain = computed(() => {
    return dataSelectedNetwork.value ? dataSelectedNetwork.value[11] : 0n
  })

  const tokenPriceUSD = computed(() => {
    return dataSelectedNetwork.value ? dataSelectedNetwork.value[12] : 0n
  })

  const airdropBalance = computed(() => {
    return dataSelectedNetwork.value && hasClaimStarted() ? dataSelectedNetwork.value[11] : 0n
  })

  const presaleEnded = computed(() => {
    return (
      currentRoundDetails.value.currentRoundNumber == roundPrices.value.length - 1 &&
      new Date().getTime() / 1000 > currentRoundDetails.value.roundFinalTime
    )
  })

  async function fetchData() {
    const promises = chains.map(async (chain) => {
      const transports = wagmiConfig.getClient({ chainId: chain.id }).transport.transports

      for (var i = 0; i < transports.length; i++) {
        try {
          if (rpcBlacklist.has(transports[i].value.url)) {
            if (rpcBlacklist.get(transports[i].value.url) + 60 * 5 * 1000 > Date.now()) {
              continue
            }

            rpcBlacklist.delete(transports[i].value.url)
          }

          const contracts = [
            {
              address: import.meta.env.VITE_PRESALE_CONTRACT,
              abi: presaleAbi,
              functionName: 'totalTokensSold'
            },
            {
              address: import.meta.env.VITE_PRESALE_CONTRACT,
              abi: presaleAbi,
              functionName: 'usdRaised'
            },
            {
              address: import.meta.env.VITE_PRESALE_CONTRACT,
              abi: presaleAbi,
              functionName: 'userDeposits',
              args: [toValue(account).address ?? zeroAddress]
            },
            {
              address: import.meta.env.VITE_PRESALE_CONTRACT,
              abi: presaleAbi,
              functionName: 'getCurrentRoundDetails'
            },
            {
              address: import.meta.env.VITE_PRESALE_CONTRACT,
              abi: presaleAbi,
              functionName: 'getRoundDetails',
              args: [1]
            },
            {
              address: import.meta.env.VITE_PRESALE_CONTRACT,
              abi: presaleAbi,
              functionName: 'maxTokensToBuy'
            },
            {
              address: import.meta.env.VITE_PRESALE_CONTRACT,
              abi: presaleAbi,
              functionName: 'minBuyInDolar'
            },
            {
              address: import.meta.env.VITE_STAKING_CONTRACT,
              abi: stakingAbi,
              functionName: 'poolStakers',
              args: [toValue(account).address ?? zeroAddress]
            },
            {
              address: import.meta.env.VITE_STAKING_CONTRACT,
              abi: stakingAbi,
              functionName: 'getRewards',
              args: [toValue(account).address ?? zeroAddress]
            },
            {
              address: import.meta.env.VITE_STAKING_CONTRACT,
              abi: stakingAbi,
              functionName: 'stakeRewardsTokensPerDay'
            },
            {
              address: import.meta.env.VITE_STAKING_CONTRACT,
              abi: stakingAbi,
              functionName: 'tokensStakedTotal'
            }
          ]

          if (!presaleEnded.value) {
            contracts.push(
              {
                address: import.meta.env.VITE_PRESALE_CONTRACT,
                abi: presaleAbi,
                functionName: 'mainBuyHelper',
                args: [1]
              },
              {
                address: import.meta.env.VITE_PRESALE_CONTRACT,
                abi: presaleAbi,
                functionName: 'usdBuyHelper',
                args: [1]
              }
            )
          }

          if (chain.id === chains[0].id && hasClaimStarted()) {
            contracts.push({
              address: import.meta.env.VITE_AIRDROP_CONTRACT,
              abi: airdropAbi,
              functionName: 'airdrop',
              args: [toValue(account).address ?? zeroAddress]
            })
          }

          const client = createPublicClient({
            chain,
            transport: http(transports[i].value.url)
          })

          const result = await client.multicall({
            chainId: chain.id,
            allowFailure: false,
            contracts
          })

          return { chainId: chain.id, result }
        } catch (error) {
          rpcBlacklist.set(transports[i].value.url, Date.now())

          if (i === transports.length - 1) {
            throw new Error(`Error occurred on chainId ${chain.id}:`, error)
          }
        }
      }
    })

    Promise.all(promises)
      .then((results) => {
        results.forEach(({ chainId, result }) => {
          data.value.set(chainId, result)
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  onMounted(() => {
    //Initialize the interval
    startFetching()

    if (!watching) {
      watching = watch(account, () => {
        if (account.value.isConnected) {
          stopFetching()
          startFetching()
        }
      })
    }
  })

  onBeforeUnmount(() => {
    if (watching) watching()
    watching = null
  })

  function startFetching() {
    if (!intervalId) {
      //Fetch for the first time
      fetchData()

      //Initialize the interval
      intervalId = setInterval(fetchData, 60000)
    }
  }

  function stopFetching() {
    if (intervalId) {
      clearInterval(intervalId)
      intervalId = null
    }
  }

  return {
    dataSelectedNetwork,
    totalTokensSold,
    tokenPriceMain,
    tokenPriceUSD,
    usdRaised,
    usdRaisedTarget,
    userTotalBalance,
    userUnstakedBalance,
    currentRoundDetails,
    roundPrices,
    remainingTokens,
    maxAmountToBuy,
    minAmountToBuy,
    stakerDetails,
    stakerRewards,
    rewardsPerDay,
    totalStaked,
    airdropBalance,
    presaleEnded,
    startFetching,
    stopFetching
  }
}
