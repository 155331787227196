export const es = {
  connect: 'Conectar',
  buy: 'Comprar',
  buyAndStake: 'Comprar y apostar',
  buyWithCard: 'Comprar con tarjeta',
  stake: 'Apostar',
  stakeNow: 'Apostar ahora',
  claimTokens: 'Reclamar',
  claimAndStake: 'Reclamar y apostar',
  claimRewards: 'Reclamar recompensas',
  loading: 'Cargando...',
  viewTransaction: 'Ver transacción',
  close: 'Cerrar',
  copy: 'Copiar',
  days: 'Día | Días',
  hours: 'Hora | Horas',
  minutes: 'Minuto | Minutos',
  seconds: 'Segundo | Segundos',
  lastRound: 'Última ronda',
  developedBy: 'Desarrollado por',
  navbar: {
    home: 'Inicio',
    staking: 'Staking',
    about: 'Acerca de',
    howToBuy: 'Cómo comprar',
    tokenomics: 'Tokenomics',
    roadmap: 'Hoja de ruta',
    faq: 'FAQ'
  },
  presale: {
    presaleOpen: 'La preventa de {token} está abierta',
    stage: 'Etapa',
    untilPriceIncrease: 'Hasta el aumento de precio',
    untilPriceIncreaseDescription:
      'El precio aumentará cuando termine el temporizador o cuando el USD recaudado alcance el objetivo.',
    nextPrice: 'Próximo precio',
    usdtRaised: 'USDT recaudado',
    yourPurchased: 'Tus {token} comprados',
    yourPurchasedDescription:
      'Total de tokens comprados con la dirección conectada en la cadena seleccionada',
    yourStakeable: 'Tus {token} apostables',
    yourStakeableDescription:
      'Total de tokens no apostados con la dirección conectada en la cadena seleccionada. Puedes apostar tus tokens ahora en la página de apuestas.',
    yourStaked: 'Tus {token} apostados',
    yourStakedDescription:
      'Total de tokens apostados con la dirección conectada en la cadena seleccionada.'
  },
  swap: {
    balance: 'Saldo',
    youPay: '{token} que pagas',
    youReceive: '{token} que recibes',
    lessThanMinimumError: 'La cantidad de {token} a comprar debe ser más grande que {min}.',
    exceedsMaxError: 'La cantidad de {token} a comprar debe ser menor que {max}.',
    notEnoughBalanceError: 'Asegúrate de tener saldo suficiente para comprar.',
    needApprovalDescription:
      'Para comprar {token} con {usdToken}, primero debes autorizarnos a acceder al {usdToken} en tu billetera. Es posible que necesites verificar la aplicación de la billetera si estás en un dispositivo móvil.',
    waitingApprovalDescription:
      'La autorización está en curso, pronto podrás confirmar la transacción.',
    needConfirmationDescription:
      'Para comprar tokens {tokenSymbol}, confirma la transacción en tu billetera. Es posible que debas verificar la aplicación de la billetera si estás en un dispositivo móvil.',
    waitingTransactionDescription:
      '{amount} tokens se comprarán una vez que la transacción sea confirmada.',
    openingWertModal: 'Espere mientras se abre la pantalla de pago.',
    waitingWertPayment:
      'Para comprar {token} con tarjeta, continúa rellenando tu información en la pantalla de pago.',
    waitingWertConfirmation:
      '{amount} tokens se comprarán una vez que el proveedor de pagos confirme tu pago.',
    successDescription: '{amount} tokens se compraron con éxito!',
    confirmationError:
      'No pudimos confirmar tu transacción, verifica si está atascada en tu billetera.',
    errorDescription: 'Algo salió mal, inténtalo de nuevo.',
    startAgain: 'Comenzar de nuevo',
    notEnoughTokens: '¿No tienes suficiente {token}? ¡Compra o intercambia ahora!',
    buyOrSwapTokens: 'Compra o intercambia tokens'
  },
  presaleOver: {
    presaleOverTitle: 'La preventa de {token} ha terminado',
    presaleOverDescription:
      'Espere hasta que comience la reclamación para reclamar y negociar sus tokens {token}. Mientras tanto, puede apostar sus tokens comprados y ganar recompensas.',
    untilClaimStart: 'Hasta que comience la reclamación'
  },
  claim: {
    claimOpen: 'La reclamación de {token} está ahora activa',
    claimOpenDescription: 'Ahora puedes reclamar y apostar tus {token} para ganar recompensas.',
    importToken:
      'Importa el token {token} en tu billetera, usando la dirección de abajo, para ver tus tokens reclamados.',
    importTokenDescription:
      'Si no sabes cómo hacer esto, busca "Cómo importar tokens personalizados" en la billetera que estás usando.',
    yourClaimable: 'Tus {token} reclamables',
    yourClaimableDescription:
      'Total de tokens disponibles para reclamar con la dirección conectada. Puedes reclamar tus tokens en tu billetera o hacer apuestas directamente.',
    yourStaked: 'Tus {token} apostados',
    yourStakedDescription: 'Total de tokens apostados con la dirección conectada.',
    buyNowOnExchange: 'Compra {token} ahora en {exchange}',
    stakeNeedConfirmation:
      'Para apostar tus {token}, confirma la transacción en tu billetera. Es posible que debas verificar la aplicación de la billetera si estás en un dispositivo móvil. Esto apostará todos tus tokens {token} reclamables. Los tokens estarán bloqueados por {lockDays} @.lower:days hasta que puedas retirarlos.',
    claimNeedConfirmation:
      'Para reclamar tus {token}, confirma la transacción en tu billetera. Es posible que debas verificar la aplicación de la billetera si estás en un dispositivo móvil. Esto reclamará todos tus tokens {token} reclamables a tu billetera conectada.',
    stakeWaitingTransaction:
      'Los tokens serán apostados una vez que la transacción sea confirmada.',
    claimWaitingTransaction:
      'Los tokens serán reclamados una vez que la transacción sea confirmada.',
    stakeSuccess: 'Tokens apostados con éxito!',
    claimSuccess:
      'Tokens reclamados con éxito! Recuerda, para ver tu saldo de {token} en tu billetera, debes importar la dirección del token anterior.',
    confirmationError:
      'No pudimos confirmar tu transacción, verifica si está atascada en tu billetera.',
    errorDescription: 'Algo salió mal, inténtalo de nuevo.',
    startAgain: 'Comenzar de nuevo'
  },
  staking: {
    welcome: 'Bienvenido al staking de {token}',
    distributionDescription:
      'La distribución de recompensas de {tokenName} ocurrirá a una tasa de {rewardsPerDay} {tokenSymbol} por día y será distribuida durante {duration} año.',
    withdrawStakedTokens: 'Retirar tokens apostados',
    stakedBalance: 'Saldo apostado',
    stakeableBalance: 'Saldo apostable',
    totalRewards: 'Recompensas totales',
    claimNotStarted:
      'El período de reclamación comenzará una vez que termine la preventa. Mantente atento al final de la preventa para comenzar a reclamar tus recompensas.',
    totalStaked: 'Total apostado',
    percentageOfPool: 'Tu % del pool',
    currentRewards: 'Recompensas actuales',
    estimatedRewards: 'Recompensas estimadas',
    rewardRateDynamic: 'La tasa de recompensa es dinámica',
    monthlyRewards: 'Mensual = % de recompensas / 12',
    dailyRewards: 'Diario = % de recompensas / 365',
    stakeNow: 'Apuesta tus {token} ahora',
    claimNow: 'Reclama tus {token} ahora',
    withdrawNow: 'Retira tus {token} ahora',
    fillAmount:
      'Ingresa la cantidad de {token} que deseas apostar abajo y confirma la transacción para comenzar a ganar recompensas.',
    amount: 'Cantidad',
    notEnoughBalanceError: 'Asegúrate de tener saldo suficiente para apostar.',
    needApprovalDescription:
      'Para apostar {token}, primero debes autorizarnos a acceder al {token} en tu billetera. Es posible que debas verificar la aplicación de la billetera si estás en un dispositivo móvil.',
    waitingApprovalDescription:
      'La autorización está en curso, pronto podrás confirmar la transacción.',
    stakeNeedConfirmation:
      'Para apostar tus tokens {token}, confirma la transacción en tu billetera. Es posible que debas verificar la aplicación de la billetera si estás en un dispositivo móvil. Esto apostará todos tus tokens {token}. Los tokens estarán bloqueados por {lockDays} @.lower:days después de que se active el proceso de reclamación.',
    claimNeedConfirmation:
      'Para reclamar tus tokens {token}, confirma la transacción en tu billetera. Es posible que debas verificar la aplicación de la billetera si estás en un dispositivo móvil. Esto reclamará todas tus recompensas de {token}.',
    withdrawNeedConfirmation:
      'Para retirar tus tokens {token}, confirma la transacción en tu billetera. Es posible que debas verificar la aplicación de la billetera si estás en un dispositivo móvil. Esto retirará todos tus tokens {token} apostados.',
    stakeWaitingTransaction:
      'Los tokens serán apostados una vez que la transacción sea confirmada.',
    claimWaitingTransaction:
      'Las recompensas serán reclamadas una vez que la transacción sea confirmada.',
    withdrawWaitingTransaction:
      'Los tokens serán retirados una vez que la transacción sea confirmada.',
    stakeSuccess: 'Tokens apostados con éxito!',
    claimSuccess: 'Tokens reclamados con éxito!',
    withdrawSuccess: 'Tokens retirados con éxito!',
    confirmationError:
      'No pudimos confirmar tu transacción, verifica si está atascada en tu billetera.',
    errorDescription: 'Algo salió mal, inténtalo de nuevo.',
    tryAgain: 'Inténtalo de nuevo'
  },
  airdrop: {
    claimTitle: 'Reclamación de Airdrop {token}',
    claimNotStartedDescription:
      'El período de reclamación del airdrop comenzará cuando termine la preventa. Mantente atento a la finalización de la preventa para comenzar a reclamar tus recompensas.',
    claimStartedDescription:
      'Ahora puedes reclamar tus {token} recibidos en el airdrop para ganar recompensas.',
    importToken:
      'Importa el token {token} en tu billetera usando la dirección de abajo para ver tus tokens reclamados.',
    importTokenDescription:
      'Si no sabes cómo hacerlo, busca "Cómo importar tokens personalizados" en la billetera que estás utilizando.',
    yourClaimable: 'Tus {token} reclamables',
    yourClaimableDescription:
      'Total de tokens recibidos durante el airdrop disponibles para reclamar con la dirección conectada.',
    claimNeedConfirmation:
      'Para reclamar tus tokens {token}, por favor confirma la transacción en tu billetera. Es posible que necesites revisar la aplicación de la billetera si estás en un móvil. Esto reclamará todos tus tokens {token} disponibles en tu billetera conectada.',
    claimWaitingTransaction:
      'Los tokens serán reclamados una vez que la transacción sea confirmada.',
    claimSuccess:
      '¡Tokens reclamados con éxito! Recuerda, para ver el saldo de {token} en tu billetera, necesitas importar la dirección del token mencionada arriba.',
    confirmationError:
      'No pudimos confirmar tu transacción, verifica si está atascada en tu billetera.',
    errorDescription: 'Algo salió mal, por favor intenta nuevamente.',
    startAgain: 'Comenzar de nuevo'
  },
  status: {
    NEED_APPROVAL: 'Necesita aprobación',
    WAITING_APPROVAL: 'Esperando confirmación de aprobación',
    NEED_CONFIRMATION: 'Necesita confirmación',
    WAITING_TRANSACTION: 'Esperando confirmación de la transacción',
    OPENINIG_WERT_MODAL: 'Abriendo pantalla de pago',
    WAITING_WERT_PAYMENT: 'Esperando pago',
    WAITING_WERT_CONFIRMATION: 'Esperando confirmación del pago',
    SUCCESS: 'Éxito',
    ERROR: 'Error'
  }
}
