import { Tooltip } from 'bootstrap'

export const tooltip = {
  mounted(el, binding) {
    const tooltipTitle = binding.value || el.getAttribute('title') || ' '
    el._tooltip = new Tooltip(el, {
      title: tooltipTitle
    })

    // Optional: Clean up the title attribute to avoid double rendering
    el.setAttribute('data-bs-original-title', tooltipTitle)
  },
  updated(el, binding) {
    const tooltipTitle = binding.value || el.getAttribute('title') || ' '
    if (el._tooltip) {
      el._tooltip.setContent({
        '.tooltip-inner': tooltipTitle
      })
    }
  },
  beforeUnmount(el) {
    if (el._tooltip) {
      el._tooltip.dispose()
    }
  }
}
