<template>
  <NavBar2 />
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  <Footer />
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { useRecaptchaProvider } from 'vue-recaptcha'

const Footer = defineAsyncComponent(() => import('./components/Footer.vue'))
const NavBar2 = defineAsyncComponent(() => import('./components/NavBar2.vue'))

useRecaptchaProvider();
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>