import { ref } from "vue";

const changellyUrl = ref("");

export function useChangelly() {
  function setChangellyUrl(url) {
    changellyUrl.value = url;
  }

  return { changellyUrl, setChangellyUrl };
}
