<script setup>

import { advertisersCrypto } from '../assets/data/index';
import AdvertisersMarquee from './AdvertisersMarquee.vue';
import Title from './base/Title.vue';

const expertise = ['Proven Marketing Strategies for ICOs', 'Customized Solutions', 'Consulting for fintechs', 'Global Reach and Network', 'Cutting-Edge Innovation']
</script>

<template>
    <div class="bg-white position-relative">
        <div class="radial-background-left"></div>
        <div class="radial-background-right"></div>
        <div class="container py-container ">
            <div data-aos="fade-up">
                <h1 class="display-5 fw-bold text-center">
                    <Title>
                        Why Choose <template #after>MintWeb3?
                        </template>
                    </Title>
                </h1>

                <p class="fs-6 text-secondary-dark my-4 text-center mx-auto" style="width: 70%;">
                    At MintWeb3, we understand that launching and scaling a Web 3.0 project requires a unique
                    combination
                    of
                    technical
                    expertise, innovative marketing, and strategic planning
                </p>
            </div>

            <div class="main-section row mt-5 mx-2" data-aos="fade-up">
                <div class="col-12 col-md-6 text-center my-auto" style="position: relative;">
                    <img style="height: 400px;width: 100%;" src="/images/chooseus.svg" alt="Choose us" />
                </div>
                <div class="col-12 col-md-6 px-0 left-border">
                    <div class="px-2 bg-primary bottom-boder d-flex align-items-center top-right-border-radius py-4"
                        data-aos="fade-up">
                        <img src="/images/bullet-white.svg" alt="Bullet" width="20" height="20" />
                        <div class="flex-grow-1 text-center fs-6">Expertise in Web3 Technologies</div>
                    </div>
                    <div v-for="(item, idx) in expertise" :class="idx < expertise.length - 1 ? 'bottom-boder' : ''"
                        class="px-2   d-flex align-items-center py-4" data-aos="fade-up">
                        <img src="/images/bullet.svg" alt="Bullet" width="20" height="20" />
                        <div class="flex-grow-1 text-center">{{ item }}</div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div>
        <AdvertisersMarquee :dark="true" :borders="true" title="Multi Network" :data="advertisersCrypto" />
    </div>
</template>

<style scoped>
.main-section {
    border: 1px solid black;
    border-radius: 18px;
}

.bottom-boder {
    border-bottom: 1px solid black;
}

.left-border {
    border-left: 1px solid black;
}

.top-right-border-radius {
    border-top-right-radius: 16px;
}

.radial-background-left {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: radial-gradient(30% 50% at 50% 50%, #25DA4A 0%, rgba(255, 255, 255, 0) 100%);
    transform: translate(-50%, -50%);
    z-index: 0;
    pointer-events: none;
    opacity: 40%;
}

.radial-background-right {
    position: absolute;
    top: 50%;
    right: 0%;
    width: 100%;
    height: 100%;
    background: radial-gradient(40% 50% at 100% 50%, #25DA4A 0%, rgba(255, 255, 255, 0) 100%);
    transform: translateY(-50%);
    z-index: 0;
    pointer-events: none;
    opacity: 40%;
}
</style>