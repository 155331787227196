<script setup>
import { hasClaimStarted } from '@/utils/helpers'
import PresaleWidget from './components/PresaleWidget.vue';
import PresaleOverWidget from './components/PresaleOverWidget.vue';
import ClaimWidget from './components/ClaimWidget.vue';
import { useData } from '@/composables/data';

const { presaleEnded } = useData()

</script>

<template>
    <div class="row justify-content-center px-2">
        <PresaleWidget v-if="!presaleEnded" />
        <PresaleOverWidget v-else-if="!hasClaimStarted()" />
        <ClaimWidget v-else />
    </div>
</template>