import { wagmiConfig } from '@/plugins/walletconnect'
import { useToken } from './token'
import { readContract, waitForTransactionReceipt, writeContract } from '@wagmi/core'
import {
  airdropAbi,
  presaleAbi,
  presaleTokenAbi,
  stakingAbi
} from '@/utils/constants/contracts_abi'
import { ref } from 'vue'
import { encodeFunctionData, formatEther, parseEther } from 'viem'
import { useAccount } from './account'
import { useData } from './data'
import { OperationType, TokenType, WriteContractStatus } from '@/utils/constants/enums'
import WertWidget from '@wert-io/widget-initializer'

import Cookies from 'js-cookie'
import axios from 'axios'

export function useContract() {
  const { selectedToken } = useToken()
  const { account, getBalances } = useAccount()
  const { tokenPriceMain, tokenPriceUSD, startFetching, stopFetching } = useData()

  const isLoading = ref(false)
  const operation = ref('')
  const transactionHash = ref('')
  const status = ref('')

  async function buy(amountReceive, amountPay, stake) {
    const affiliate = Cookies.get('afl') ?? ''
    switch (selectedToken.value.type) {
      case TokenType.Main:
        await buyWithMain(amountReceive, amountPay, stake, affiliate)
        break
      case TokenType.Token:
        await buyWithToken(amountReceive, amountPay, stake, affiliate)
        break
      case TokenType.Card:
        await buyWithWert(amountReceive, amountPay, stake, affiliate)
        break
    }
  }

  async function buyWithToken(amountReceive, amountPay, stake, affiliate) {
    try {
      isLoading.value = true

      //Check allowance
      const allowance = await readContract(wagmiConfig, {
        abi: selectedToken.value.abi,
        address: selectedToken.value.contract,
        functionName: 'allowance',
        args: [account.value.address, import.meta.env.VITE_PRESALE_CONTRACT]
      })

      //If allowance is not enough, request approval
      if (allowance < Math.ceil(amountPay * Math.pow(10, selectedToken.value.decimals))) {
        status.value = WriteContractStatus.NeedApproval

        const approveHash = await writeContract(wagmiConfig, {
          abi: selectedToken.value.abi,
          address: selectedToken.value.contract,
          functionName: 'approve',
          args: [
            import.meta.env.VITE_PRESALE_CONTRACT,
            10000000 * Math.pow(10, selectedToken.value.decimals)
          ]
        })

        status.value = WriteContractStatus.WaitingApproval

        await waitForTransactionReceipt(wagmiConfig, {
          hash: approveHash
        })
      }

      status.value = WriteContractStatus.NeedConfirmation

      transactionHash.value = await writeContract(wagmiConfig, {
        abi: presaleAbi,
        address: import.meta.env.VITE_PRESALE_CONTRACT,
        functionName: selectedToken.value.symbol == 'USDT' ? 'buyWithUSDT' : 'buyWithUSDC',
        args: [amountReceive, stake, affiliate]
      })

      status.value = WriteContractStatus.WaitingTransaction

      await waitForTransactionReceipt(wagmiConfig, {
        hash: transactionHash.value
      })

      status.value = WriteContractStatus.Success

      //Reset fetch interval
      stopFetching()
      startFetching()
      getBalances()
    } catch (e) {
      status.value = WriteContractStatus.Error
      console.error(e)
    } finally {
      isLoading.value = false
    }
  }

  async function buyWithMain(amountReceive, amountPay, stake, affiliate) {
    try {
      isLoading.value = true

      status.value = WriteContractStatus.NeedConfirmation

      transactionHash.value = await writeContract(wagmiConfig, {
        abi: presaleAbi,
        address: import.meta.env.VITE_PRESALE_CONTRACT,
        functionName: 'buyWithMain',
        args: [amountReceive, stake, affiliate],
        value: parseEther(amountPay)
      })

      status.value = WriteContractStatus.WaitingTransaction

      await waitForTransactionReceipt(wagmiConfig, {
        hash: transactionHash.value
      })

      status.value = WriteContractStatus.Success

      //Reset fetch interval
      stopFetching()
      startFetching()
      getBalances()
    } catch (e) {
      status.value = WriteContractStatus.Error
      console.error(e)
    } finally {
      isLoading.value = false
    }
  }

  async function buyWithWert(amountReceive, amountPay, stake, affiliate) {
    try {
      isLoading.value = true

      status.value = WriteContractStatus.OpeningWertModal

      //Encode smart contract call data
      const sc_input_data = encodeFunctionData({
        abi: presaleAbi,
        functionName: 'buyWithWert',
        args: [account.value.address, amountReceive, stake, 0, affiliate]
      })

      //Convert amountPay to ETH
      const priceMainInUSD =
        Number(amountPay) *
        Number(formatEther(tokenPriceMain.value) / Number(formatEther(tokenPriceUSD.value))) *
        1.03

      let signedData

      await axios
        .post(import.meta.env.VITE_WERT_SIGN_URL, {
          headers: { 'Content-Type': 'application/json' },
          data: {
            address: account.value.address,
            commodity: selectedToken.value.wert_commodity,
            network: selectedToken.value.wert_network,
            commodity_amount: Number(priceMainInUSD.toFixed(8)),
            sc_address: import.meta.env.VITE_PRESALE_CONTRACT,
            sc_input_data: sc_input_data
          }
        })
        .then((response) => {
          signedData = response.data
        })
        .catch((error) => console.error(error))

      const wertWidget = new WertWidget({
        partner_id: import.meta.env.VITE_WERT_PARTNER_ID,
        origin: import.meta.env.VITE_WERT_ORIGIN,
        listeners: {
          loaded: () => {
            status.value = WriteContractStatus.WaitingWertPayment
          },
          error: (err) => {
            status.value = WriteContractStatus.Error
          },
          'payment-status': (data) => {
            if (data.status === 'pending') {
              if (data.tx_id) {
                status.value = WriteContractStatus.Success
                transactionHash.value = data.tx_id
              } else {
                status.value = WriteContractStatus.WaitingWertConfirmation
              }
            } else if (data.status === 'success') {
              status.value = WriteContractStatus.Success
              transactionHash.value = data.tx_id
            } else if (
              data.status === 'canceled' ||
              data.status === 'failed' ||
              data.status === 'failover'
            ) {
              status.value = WriteContractStatus.Error
            }
          },
          close: () => {
            if (
              status.value !== WriteContractStatus.Success &&
              status.value !== WriteContractStatus.Error &&
              transactionHash.value == ''
            ) {
              status.value = WriteContractStatus.Error
            }
          }
        },
        ...signedData,
        extra: {
          item_info: {
            image_url: `${import.meta.env.VITE_APP_URL}/coins-icons/${import.meta.env.VITE_PRESALE_TOKEN_SYMBOL}.svg`,
            name: `${import.meta.env.VITE_PRESALE_TOKEN_SYMBOL} Presale`
          }
        }
      })

      wertWidget.open()
    } catch (err) {
      status.value = WriteContractStatus.Error
      console.error(err)
    } finally {
      isLoading.value = false
    }
  }

  async function stake() {
    try {
      isLoading.value = true
      operation.value = OperationType.Stake

      status.value = WriteContractStatus.NeedConfirmation

      transactionHash.value = await writeContract(wagmiConfig, {
        abi: presaleAbi,
        address: import.meta.env.VITE_PRESALE_CONTRACT,
        functionName: 'stake'
      })

      status.value = WriteContractStatus.WaitingTransaction

      await waitForTransactionReceipt(wagmiConfig, {
        hash: transactionHash.value
      })

      status.value = WriteContractStatus.Success

      //Reset fetch interval
      stopFetching()
      startFetching()
    } catch (e) {
      status.value = WriteContractStatus.Error
      console.error(e)
    } finally {
      isLoading.value = false
    }
  }

  async function claimTokens() {
    try {
      isLoading.value = true
      operation.value = OperationType.Claim

      status.value = WriteContractStatus.NeedConfirmation

      transactionHash.value = await writeContract(wagmiConfig, {
        abi: presaleAbi,
        address: import.meta.env.VITE_PRESALE_CONTRACT,
        functionName: 'claim'
      })

      status.value = WriteContractStatus.WaitingTransaction

      await waitForTransactionReceipt(wagmiConfig, {
        hash: transactionHash.value
      })

      status.value = WriteContractStatus.Success

      //Reset fetch interval
      stopFetching()
      startFetching()
      getBalances()
    } catch (e) {
      status.value = WriteContractStatus.Error
      console.error(e)
    } finally {
      isLoading.value = false
    }
  }

  async function claimStake() {
    try {
      isLoading.value = true
      operation.value = OperationType.Claim

      status.value = WriteContractStatus.NeedConfirmation

      transactionHash.value = await writeContract(wagmiConfig, {
        abi: stakingAbi,
        address: import.meta.env.VITE_STAKING_CONTRACT,
        functionName: 'harvestRewards'
      })

      status.value = WriteContractStatus.WaitingTransaction

      await waitForTransactionReceipt(wagmiConfig, {
        hash: transactionHash.value
      })

      status.value = WriteContractStatus.Success

      //Reset fetch interval
      stopFetching()
      startFetching()
      getBalances()
    } catch (e) {
      status.value = WriteContractStatus.Error
      console.error(e)
    } finally {
      isLoading.value = false
    }
  }

  async function claimAirdrop() {
    try {
      isLoading.value = true
      operation.value = OperationType.Claim

      status.value = WriteContractStatus.NeedConfirmation

      transactionHash.value = await writeContract(wagmiConfig, {
        abi: airdropAbi,
        address: import.meta.env.VITE_AIRDROP_CONTRACT,
        functionName: 'claim'
      })

      status.value = WriteContractStatus.WaitingTransaction

      await waitForTransactionReceipt(wagmiConfig, {
        hash: transactionHash.value
      })

      status.value = WriteContractStatus.Success

      //Reset fetch interval
      stopFetching()
      startFetching()
      getBalances()
    } catch (e) {
      status.value = WriteContractStatus.Error
      console.error(e)
    } finally {
      isLoading.value = false
    }
  }

  async function deposit(amount) {
    try {
      isLoading.value = true
      operation.value = OperationType.Stake

      //Check allowance
      const allowance = await readContract(wagmiConfig, {
        abi: presaleTokenAbi,
        address: import.meta.env.VITE_PRESALE_TOKEN_CONTRACT,
        functionName: 'allowance',
        args: [account.value.address, import.meta.env.VITE_STAKING_CONTRACT]
      })

      const amountWei =
        BigInt(Number(amount)) * BigInt(10) ** BigInt(import.meta.env.VITE_PRESALE_TOKEN_DECIMALS)

      //If allowance is not enough, request approval
      if (allowance < amountWei) {
        status.value = WriteContractStatus.NeedApproval

        const approveHash = await writeContract(wagmiConfig, {
          abi: presaleTokenAbi,
          address: import.meta.env.VITE_PRESALE_TOKEN_CONTRACT,
          functionName: 'approve',
          args: [
            import.meta.env.VITE_STAKING_CONTRACT,
            100000000n * 10n ** BigInt(import.meta.env.VITE_PRESALE_TOKEN_DECIMALS)
          ]
        })

        status.value = WriteContractStatus.WaitingApproval

        await waitForTransactionReceipt(wagmiConfig, {
          hash: approveHash
        })
      }

      status.value = WriteContractStatus.NeedConfirmation

      transactionHash.value = await writeContract(wagmiConfig, {
        abi: stakingAbi,
        address: import.meta.env.VITE_STAKING_CONTRACT,
        functionName: 'deposit',
        args: [account.value.address, amountWei]
      })

      status.value = WriteContractStatus.WaitingTransaction

      await waitForTransactionReceipt(wagmiConfig, {
        hash: transactionHash.value
      })

      status.value = WriteContractStatus.Success

      //Reset fetch interval
      stopFetching()
      startFetching()
      getBalances()
    } catch (e) {
      status.value = WriteContractStatus.Error
      console.error(e)
    } finally {
      isLoading.value = false
    }
  }

  async function withdraw() {
    try {
      isLoading.value = true
      operation.value = OperationType.Withdraw

      status.value = WriteContractStatus.NeedConfirmation

      transactionHash.value = await writeContract(wagmiConfig, {
        abi: stakingAbi,
        address: import.meta.env.VITE_STAKING_CONTRACT,
        functionName: 'withdraw'
      })

      status.value = WriteContractStatus.WaitingTransaction

      await waitForTransactionReceipt(wagmiConfig, {
        hash: transactionHash.value
      })

      status.value = WriteContractStatus.Success

      //Reset fetch interval
      stopFetching()
      startFetching()
      getBalances()
    } catch (e) {
      status.value = WriteContractStatus.Error
      console.error(e)
    } finally {
      isLoading.value = false
    }
  }

  function clearState() {
    isLoading.value = false
    operation.value = ''
    transactionHash.value = ''
    status.value = ''
  }

  return {
    isLoading,
    operation,
    transactionHash,
    status,
    buy,
    stake,
    claimTokens,
    claimStake,
    claimAirdrop,
    deposit,
    withdraw,
    clearState
  }
}
