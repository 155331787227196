export const fr = {
  connect: 'Connecter',
  buy: 'Acheter',
  buyAndStake: 'Acheter et miser',
  buyWithCard: 'Acheter avec carte',
  stake: 'Miser',
  stakeNow: 'Miser maintenant',
  claimTokens: 'Réclamer',
  claimAndStake: 'Réclamer et miser',
  claimRewards: 'Réclamer les récompenses',
  loading: 'Chargement...',
  viewTransaction: 'Voir la transaction',
  close: 'Fermer',
  copy: 'Copier',
  days: 'Jour | Jours',
  hours: 'Heure | Heures',
  minutes: 'Minute | Minutes',
  seconds: 'Seconde | Secondes',
  lastRound: 'Dernier tour',
  developedBy: 'Développé par',
  navbar: {
    home: 'Accueil',
    staking: 'Staking',
    about: 'À propos',
    howToBuy: 'Comment acheter',
    tokenomics: 'Tokenomics',
    roadmap: 'Feuille de route',
    faq: 'FAQ'
  },
  presale: {
    presaleOpen: 'La prévente de {token} est maintenant ouverte',
    stage: 'Étape',
    untilPriceIncrease: "Jusqu'à l'augmentation de prix",
    untilPriceIncreaseDescription:
      "Le prix augmentera lorsque le minuteur se termine ou lorsque l'objectif de USD collecté sera atteint.",
    nextPrice: 'Prochain prix',
    usdtRaised: 'USDT Collectés',
    yourPurchased: 'Ton {token} acheté',
    yourPurchasedDescription:
      "Total des jetons achetés avec l'adresse connectée sur la chaîne sélectionnée",
    yourStakeable: 'Tes {token} disponibles pour miser',
    yourStakeableDescription:
      "Total des jetons non misés avec l'adresse connectée sur la chaîne sélectionnée. Vous pouvez miser vos jetons maintenant sur la page de mise.",
    yourStaked: 'Tes {token} misés',
    yourStakedDescription:
      "Total des jetons misés avec l'adresse connectée sur la chaîne sélectionnée."
  },
  swap: {
    balance: 'Solde',
    youPay: 'Vous payez {token}',
    youReceive: 'Vous recevez {token}',
    lessThanMinimumError: 'Le montant de { token } à acheter doit être supérieur à { min }.',
    exceedsMaxError: 'Le montant de { token } à acheter doit être inférieur à { max }.',
    notEnoughBalanceError: "Assurez-vous d'avoir suffisamment de solde pour acheter.",
    needApprovalDescription:
      "Pour acheter {token} avec {usdToken}, vous devez d'abord nous autoriser à accéder à {usdToken} dans votre portefeuille. Vous devrez peut-être vérifier l'application du portefeuille si vous êtes sur mobile.",
    waitingApprovalDescription:
      "L'autorisation est en cours, vous pourrez confirmer la transaction sous peu.",
    needConfirmationDescription:
      "Pour acheter des jetons {tokenSymbol}, veuillez confirmer la transaction dans votre portefeuille. Vous devrez peut-être vérifier l'application du portefeuille si vous êtes sur mobile.",
    waitingTransactionDescription:
      '{amount} jetons seront achetés une fois la transaction confirmée.',
    openingWertModal: "Veuillez patienter pendant que l'écran de paiement s'ouvre.",
    waitingWertPayment:
      "Pour acheter {token} avec une carte, remplissez vos informations sur l'écran de paiement.",
    waitingWertConfirmation:
      '{amount} tokens seront achetés une fois que le prestataire de paiement aura confirmé votre paiement.',
    successDescription: '{amount} jetons ont été achetés avec succès!',
    confirmationError:
      "Nous n'avons pas pu confirmer votre transaction, veuillez vérifier si elle est bloquée dans votre portefeuille.",
    errorDescription: "Quelque chose s'est mal passé, veuillez réessayer.",
    startAgain: 'Recommencer',
    notEnoughTokens: 'Pas assez de {token}? Achetez ou échangez maintenant !',
    buyOrSwapTokens: 'Achetez ou échangez des tokens'
  },
  presaleOver: {
    presaleOverTitle: 'La prévente de {token} est terminée',
    presaleOverDescription:
      'Attendez le début de la réclamation pour récupérer et négocier vos tokens {token}. En attendant, vous pouvez miser vos tokens achetés et gagner des récompenses.',
    untilClaimStart: 'Jusqu’au début de la réclamation'
  },
  claim: {
    claimOpen: 'La réclamation de {token} est maintenant active',
    claimOpenDescription:
      'Vous pouvez maintenant réclamer et miser vos {token} pour gagner des récompenses.',
    importToken:
      "Importez le jeton {token} dans votre portefeuille, en utilisant l'adresse ci-dessous, pour voir vos jetons réclamés.",
    importTokenDescription:
      'Si vous ne savez pas comment faire cela, recherchez "Comment importer des jetons personnalisés" dans le portefeuille que vous utilisez.',
    yourClaimable: 'Tes {token} à réclamer',
    yourClaimableDescription:
      "Total des jetons disponibles à réclamer avec l'adresse connectée. Vous pouvez réclamer vos jetons dans votre portefeuille ou les miser directement.",
    yourStaked: 'Tes {token} misés',
    yourStakedDescription: "Total des jetons misés avec l'adresse connectée.",
    buyNowOnExchange: 'Achetez {token} maintenant sur {exchange}',
    stakeNeedConfirmation:
      "Pour miser vos {token}, confirmez la transaction dans votre portefeuille. Vous devrez peut-être vérifier l'application du portefeuille si vous êtes sur mobile. Cela misera tous vos jetons {token} réclamables. Les jetons seront verrouillés pendant {lockDays} @.lower:days jusqu'à ce que vous puissiez les retirer.",
    claimNeedConfirmation:
      "Pour réclamer vos {token}, confirmez la transaction dans votre portefeuille. Vous devrez peut-être vérifier l'application du portefeuille si vous êtes sur mobile. Cela réclamera tous vos jetons {token} à votre portefeuille connecté.",
    stakeWaitingTransaction: 'Les jetons seront misés une fois la transaction confirmée.',
    claimWaitingTransaction: 'Les jetons seront réclamés une fois la transaction confirmée.',
    stakeSuccess: 'Les jetons ont été misés avec succès!',
    claimSuccess:
      "Les jetons ont été réclamés avec succès! N'oubliez pas, pour voir votre solde {token} dans votre portefeuille, vous devez importer l'adresse du jeton ci-dessus.",
    confirmationError:
      "Nous n'avons pas pu confirmer votre transaction, veuillez vérifier si elle est bloquée dans votre portefeuille.",
    errorDescription: "Quelque chose s'est mal passé, veuillez réessayer.",
    startAgain: 'Recommencer'
  },
  staking: {
    welcome: 'Bienvenue dans les mises de {token}',
    distributionDescription:
      'La distribution des récompenses en jetons {tokenName} se fera à un taux de {rewardsPerDay} {tokenSymbol} par jour et sera distribuée sur {duration} an.',
    withdrawStakedTokens: 'Retirer les jetons misés',
    stakedBalance: 'Solde misé',
    stakeableBalance: 'Solde disponible à miser',
    totalRewards: 'Récompenses totales',
    claimNotStarted:
      "La période de réclamation commencera une fois la prévente terminée. Restez à l'écoute pour le début des réclamations après la fin de la prévente.",
    totalStaked: 'Total misé',
    percentageOfPool: 'Votre % du pool',
    currentRewards: 'Récompenses actuelles',
    estimatedRewards: 'Récompenses estimées',
    rewardRateDynamic: 'Le taux de récompense est dynamique',
    monthlyRewards: 'Mensuel = % des récompenses / 12',
    dailyRewards: 'Quotidien = % des récompenses / 365',
    stakeNow: 'Misez vos {token} maintenant',
    claimNow: 'Réclamez vos {token} maintenant',
    withdrawNow: 'Retirez vos {token} maintenant',
    fillAmount:
      'Entrez le montant de {token} que vous souhaitez miser ci-dessous et confirmez la transaction pour commencer à gagner des récompenses.',
    amount: 'Montant',
    notEnoughBalanceError: "Assurez-vous d'avoir suffisamment de solde pour miser.",
    needApprovalDescription:
      "Pour miser {token}, vous devez d'abord nous autoriser à accéder à {token} dans votre portefeuille. Vous devrez peut-être vérifier l'application du portefeuille si vous êtes sur mobile.",
    waitingApprovalDescription:
      "L'autorisation est en cours, vous pourrez confirmer la transaction sous peu.",
    stakeNeedConfirmation:
      "Pour miser vos jetons {token}, confirmez la transaction dans votre portefeuille. Vous devrez peut-être vérifier l'application du portefeuille si vous êtes sur mobile. Cela misera tous vos jetons {token}. Les jetons seront verrouillés pendant {lockDays} @.lower:days après le début du processus de réclamation.",
    claimNeedConfirmation:
      "Pour réclamer vos jetons {token}, confirmez la transaction dans votre portefeuille. Vous devrez peut-être vérifier l'application du portefeuille si vous êtes sur mobile. Cela réclamera toutes vos récompenses de {token}.",
    withdrawNeedConfirmation:
      "Pour retirer vos jetons {token}, confirmez la transaction dans votre portefeuille. Vous devrez peut-être vérifier l'application du portefeuille si vous êtes sur mobile. Cela retirera tous vos jetons {token} misés.",
    stakeWaitingTransaction: 'Les jetons seront misés une fois la transaction confirmée.',
    claimWaitingTransaction: 'Les récompenses seront réclamées une fois la transaction confirmée.',
    withdrawWaitingTransaction: 'Les jetons seront retirés une fois la transaction confirmée.',
    stakeSuccess: 'Les jetons ont été misés avec succès!',
    claimSuccess: 'Les jetons ont été réclamés avec succès!',
    withdrawSuccess: 'Les jetons ont été retirés avec succès!',
    confirmationError:
      "Nous n'avons pas pu confirmer votre transaction, veuillez vérifier si elle est bloquée dans votre portefeuille.",
    errorDescription: "Quelque chose s'est mal passé, veuillez réessayer.",
    tryAgain: 'Réessayer'
  },
  airdrop: {
    claimTitle: "Réclamation de l'Airdrop {token}",
    claimNotStartedDescription:
      "La période de réclamation de l'airdrop commencera une fois la prévente terminée. Restez à l'écoute de la fin de la prévente pour commencer à réclamer vos récompenses.",
    claimStartedDescription:
      "Vous pouvez maintenant réclamer vos {token} reçus dans l'airdrop pour gagner des récompenses.",
    importToken:
      "Importez le token {token} dans votre portefeuille, en utilisant l'adresse ci-dessous, pour voir vos tokens réclamés.",
    importTokenDescription:
      'Si vous ne savez pas comment faire cela, recherchez "Comment importer des tokens personnalisés" dans le portefeuille que vous utilisez.',
    yourClaimable: 'Vos {token} réclamables',
    yourClaimableDescription:
      "Total des tokens reçus lors de l'airdrop disponibles à réclamer avec l'adresse connectée.",
    claimNeedConfirmation:
      "Pour réclamer vos tokens {token}, veuillez confirmer la transaction dans votre portefeuille. Vous devrez peut-être vérifier l'application du portefeuille si vous utilisez un mobile. Cela réclamera tous vos tokens {token} disponibles sur votre portefeuille connecté.",
    claimWaitingTransaction:
      'Les tokens seront réclamés une fois que la transaction sera confirmée.',
    claimSuccess:
      "Les tokens ont été réclamés avec succès ! N'oubliez pas, pour voir le solde de {token} dans votre portefeuille, vous devez importer l'adresse du token mentionnée ci-dessus.",
    confirmationError:
      "Nous n'avons pas pu confirmer votre transaction, veuillez vérifier si elle est bloquée dans votre portefeuille.",
    errorDescription: "Quelque chose s'est mal passé, veuillez réessayer.",
    startAgain: 'Recommencer'
  },
  status: {
    NEED_APPROVAL: "Besoin d'autorisation",
    WAITING_APPROVAL: "En attente de confirmation de l'autorisation",
    NEED_CONFIRMATION: 'Besoin de confirmation',
    WAITING_TRANSACTION: 'En attente de confirmation de la transaction',
    OPENINIG_WERT_MODAL: "Ouverture de l'écran de paiement",
    WAITING_WERT_PAYMENT: 'En attente du paiement',
    WAITING_WERT_CONFIRMATION: 'En attente de la confirmation du paiement',
    SUCCESS: 'Succès',
    ERROR: 'Erreur'
  }
}
