<script setup>
import { useData } from '@/composables/data'
import { computed, onUnmounted } from 'vue'
import { copyToClipboard, formatNumber, openModal } from '@/utils/helpers'
import { formatEther } from 'viem'
import { useAccount } from '@/composables/account'
import { useContract } from '@/composables/contract'
import { OperationType, WriteContractStatus } from '@/utils/constants/enums'
import DevelopedByComponent from './DevelopedByComponent.vue'
import { useChain } from '@/composables/chain'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
const { account } = useAccount()
const { selectedChain } = useChain()
const { dataSelectedNetwork, userUnstakedBalance, stakerDetails, stopFetching } = useData()
const { isLoading, operation, transactionHash, status, stake, claimTokens, clearState } =
  useContract()

const tokenSymbol = import.meta.env.VITE_PRESALE_TOKEN_SYMBOL
const tokenAddress = import.meta.env.VITE_PRESALE_TOKEN_CONTRACT

const unstakedBalance = computed(() => {
  return formatNumber(formatEther(userUnstakedBalance.value), 0, 0)
})

const stakedBalance = computed(() => {
  return formatNumber(formatEther(stakerDetails.value.amount), 0, 0)
})

const hasBalanceToClaim = computed(() => {
  return formatEther(userUnstakedBalance.value) > 0
})

onUnmounted(() => {
  stopFetching()
})

function openTransaction() {
  const url = selectedChain.value.blockExplorers.default.url + '/tx/' + transactionHash.value
  window.open(url, '_blank')
}
</script>

<template>
  <div class="border rounded p-1">
    <div class="mx-auto py-3 px-2 border rounded">
      <div class="px-0 w-100 d-flex flex-column align-items-center justify-content-center text-center">
        <p class="font-24 fw-bold text-capitalize mb-2">
          {{ $t('claim.claimOpen', { token: tokenSymbol }) }}
        </p>

        <p class="font-18 fw-medium mb-1">
          {{ $t('claim.claimOpenDescription', { token: tokenSymbol }) }}
        </p>

        <p class="font-14 mb-1 mt-2">
          {{ $t('claim.importToken', { token: tokenSymbol }) }}
          <img src="@/assets/icons/info.svg" width="18" height="18" alt="Import token info" class="ms-1 cursor-pointer"
            :class="dataSelectedNetwork === undefined ? 'd-none' : ''" style="padding-bottom: 2px"
            v-tooltip="$t('claim.importTokenDescription')" />
        </p>

        <p class="font-14 fw-medium cursor-pointer" :class="dataSelectedNetwork === undefined ? 'shimmer' : 'w-100'"
          style="word-break: break-word" @click="copyToClipboard(tokenAddress)">
          {{ tokenAddress }}
          <img src="@/assets/icons/copy.svg" width="16" height="16" alt="Copy" class="ms-1 cursor-pointer"
            :class="dataSelectedNetwork === undefined ? 'd-none' : ''" style="padding-bottom: 2px"
            v-tooltip="$t('copy')" @click="copyToClipboard(tokenAddress)" />
        </p>

        <div class="mb-2 font-14 text-uppercase" :class="dataSelectedNetwork === undefined ? 'shimmer' : 'w-100'">
          <span>{{ $t('claim.yourClaimable', { token: tokenSymbol }) }}: </span>
          <span>{{ unstakedBalance }}</span>
          <img src="@/assets/icons/info.svg" width="20" height="20" alt="Claimable info" class="ms-2 cursor-pointer"
            :class="dataSelectedNetwork === undefined ? 'd-none' : ''" style="padding-bottom: 2px"
            v-tooltip="$t('claim.yourClaimableDescription')" />
        </div>
      </div>

      <div class="mb-2 font-14 text-uppercase" :class="dataSelectedNetwork === undefined ? 'shimmer' : 'w-100'">
        <span>{{ $t('claim.yourStaked', { token: tokenSymbol }) }}: </span>
        <span>{{ stakedBalance }}</span>
        <img src="@/assets/icons/info.svg" width="20" height="20" alt="Staked info" class="ms-2 cursor-pointer"
          :class="dataSelectedNetwork === undefined ? 'd-none' : ''" style="padding-bottom: 2px"
          v-tooltip="$t('claim.yourStakedDescription')" />
      </div>

      <div v-if="!isLoading && status === ''">
        <div class="px-3 pb-3 my-3 w-100 d-flex flex-column align-items-center justify-content-center text-center">
          <div class="d-grid gap-2 w-100 align-items-center mt-4">
            <a class="btn btn-primary" href="https://example.com" target="_blank">
              {{ $t('claim.buyNowOnExchange', { token: tokenSymbol, exchange: 'EXCHANGE' }) }}
            </a>
            <button v-if="!account.isConnected" type="button" class="btn btn-primary" @click="openModal">
              {{ $t('connect') }}
            </button>
            <div v-else class="d-flex flex-column flex-md-row w-100 gap-2">
              <button type="button" class="btn btn-primary w-100" :class="[!hasBalanceToClaim ? 'disabled' : '']"
                @click="stake">
                {{ $t('claimAndStake') }}
              </button>
              <button type="button" class="btn btn-primary w-100" :class="[!hasBalanceToClaim ? 'disabled' : '']"
                @click="claimTokens">
                {{ $t('claimTokens') }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="d-flex flex-column w-100 align-items-center justify-content-center gap-2 mt-4">
        <div v-if="isLoading" class="spinner-border text-primary m-2">
          <span class="visually-hidden">{{ $t('loading') }}</span>
        </div>

        <img v-if="status === WriteContractStatus.Success" src="@/assets/icons/success.svg" alt="Success icon"
          width="55" height="55" />
        <img v-else-if="status === WriteContractStatus.Error" src="@/assets/icons/error.svg" alt="Error icon"
          width="55" height="55" />

        <div v-if="status !== ''" class="fw-normal fs-6 mt-1 text-uppercase text-center" :class="status === WriteContractStatus.Error
          ? 'text-danger'
          : status === WriteContractStatus.Success
            ? 'text-success'
            : 'text-secondary'
          ">
          {{ $t('status.' + status.description) }}
        </div>

        <div v-if="status === WriteContractStatus.NeedConfirmation">
          <p class="font-14 mb-3 text-center">
            <span v-if="operation === OperationType.Stake">
              {{
                $t(
                  'claim.stakeNeedConfirmation',
                  {
                    token: tokenSymbol,
                    lockDays: claimLockDays
                  },
                  claimLockDays
                )
              }}
            </span>
            <span v-if="operation === OperationType.Claim">
              {{ $t('claim.claimNeedConfirmation', { token: tokenSymbol }) }}
            </span>
          </p>
        </div>

        <div v-if="status === WriteContractStatus.WaitingTransaction">
          <p class="font-14 mb-3 text-center">
            <span v-if="operation === OperationType.Stake">
              {{ $t('claim.stakeWaitingTransaction') }}
            </span>
            <span v-if="operation === OperationType.Claim">
              {{ $t('claim.claimWaitingTransaction') }}
            </span>
          </p>
        </div>

        <div v-if="status === WriteContractStatus.Success || status === WriteContractStatus.Error" class="w-100">
          <div v-if="status === WriteContractStatus.Success">
            <p class="font-14 mb-3 text-center">
              <span v-if="operation === OperationType.Stake">
                {{ $t('claim.stakeSuccess') }}
              </span>
              <span v-if="operation === OperationType.Claim">
                {{ $t('claim.claimSuccess', { token: tokenSymbol }) }}
              </span>
            </p>
          </div>
          <p v-else-if="transactionHash" class="font-14 mb-3 text-center text-danger">
            {{ $t('claim.confirmationError') }}
          </p>
          <p v-else class="font-14 mb-3 text-center text-danger">
            {{ $t('claim.errorDescription') }}
          </p>

          <RouterLink v-if="operation === OperationType.Claim && transactionHash" :to="{ path: `/${locale}/staking` }"
            class="btn btn-primary w-100">
            {{ $t('stakeNow') }}
          </RouterLink>
          <div class="d-flex flex-column flex-lg-row w-100 gap-2 mt-2">
            <button v-if="transactionHash" type="button" class="btn btn-primary w-100" @click="openTransaction">
              {{ $t('viewTransaction') }}
            </button>
            <button type="button" class="btn btn-primary w-100" @click="clearState">
              {{ $t('swap.startAgain') }}
            </button>
          </div>
        </div>
      </div>

      <DevelopedByComponent />
    </div>
  </div>
</template>
