<script setup>
import { advertisers, coinsUp, coinsDown, blockChainServices } from '../assets/data/index';
import DetailsCard from '../components/base/DetailsCard.vue';
import DetailsList from '../components/base/DetailsList.vue';
import OutlinedBtn from '../components/base/OutlinedBtn.vue';
import SecondaryBtn from '../components/base/SecondaryBtn.vue';
import Title from '../components/base/Title.vue';
import ChooseUs from '../components/ChooseUs.vue';
import AdvertisersMarquee from '../components/AdvertisersMarquee.vue';
import CoinsMarquee from '../components/CoinsMarquee.vue';
import { Vue3Lottie } from 'vue3-lottie'
import Hero2 from '@/assets/hero-2.json'

const aboutItems = ['To democratize access to Web 3.0 by delivering innovative, scalable, and secure blockchain solutions.', 'We bridge the gap between traditional companies and the decentralized economy.', 'We guarantee seamless integration and maximum value for our customers.']
</script>

<template>
    <main>
        <section>
            <div class="radial-background-left-mobile"></div>
            <div class="radial-background-right-mobile"></div>
            <CoinsMarquee class="image-mobile" :data="coinsUp" :direction="'reverse'" />

            <div class="hero-2">
                <vue3-lottie class="lottie-image" :animation-data="Hero2" :loop="true" :autoplay="true" />
                <div class="hero-2-content ">
                    <div class="min-vh-100 d-flex" style="flex-direction: column;justify-content: center; gap: 70px;"
                        data-aos="fade-up">
                        <div class="container">
                            <div class="row  align-items-center justify-content-center text-center">
                                <div class="col-md-10">
                                    <OutlinedBtn>
                                        <template #title>
                                            <div class="d-flex align-items-center gap-1">
                                                Join Us Free World
                                                <img src="/images/right-arrow.svg" alt="Right arrow" width="16"
                                                    height="16" />
                                            </div>
                                        </template>
                                    </OutlinedBtn>
                                    <h1 class="display-2 text-white fw-bold my-4">
                                        <Title>
                                            <span class="text-white">WEB 3.0</span>
                                            <br />
                                            <template #after>DEVELOPMENT</template>
                                        </Title>
                                    </h1>
                                    <p class="text-secondary-light mx-auto" style="max-width: 70%;">Web 3.0 development
                                        creates
                                        decentralized apps (dApps)
                                        using
                                        blockchain for user-controlled
                                        data and secure peer-to-peer interactions.
                                    </p>
                                    <div class="mt-4">
                                        <SecondaryBtn title="Discover Those Worlds" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CoinsMarquee class="image-mobile" :data="coinsDown" />
        </section>

        <AdvertisersMarquee :dark="true" :borders="true" :data="advertisers" />

        <div>
            <DetailsCard :gradient="true">
                <template #header>
                    <h1 class="display-5 fw-bold">
                        <Title>
                            <template #before>About</template>Us
                        </Title>
                    </h1>
                </template>

                <template #body>
                    <div class="fs-6 text-secondary-dark">
                        At MintWeb3, we empower businesses and individuals with cutting-edge Web 3.0 solutions. From
                        token development to full-scale ICO management and marketing, we’re here to help you unlock the
                        potential of the decentralized internet. Based in Hong Kong, a global leader in finance and
                        technology, we offer unparalleled expertise in blockchain, cryptocurrency and digital payments.
                    </div>

                    <div class="fw-bold fs-4 mt-3">
                        Our Mission
                    </div>

                    <div v-for="(item, idx) in aboutItems" :key="idx" class="d-flex align-items-center gap-2 mt-2">
                        <img width="14" height="14" src="/images/bullet.svg" alt="Bullet" />
                        <div class="text-secondary-dark fs-6">{{ item }}</div>
                    </div>
                </template>

                <template #image>
                    <img style="width: 100%;" src="/images/about.svg" alt="About" />
                </template>
            </DetailsCard>

        </div>

        <div class="container py-container">
            <div class="text-white display-5 fw-bold text-center" data-aos="fade-up">
                <Title>
                    Discover
                    <template #after>
                        Our Solutions
                    </template>
                </Title>
            </div>
            <div class="py-container">
                <DetailsList :secondary="true" :data="blockChainServices" />
            </div>
        </div>

        <ChooseUs />
    </main>
</template>
