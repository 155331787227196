<script setup>
const props = defineProps(['data', 'secondary'])
</script>
<template>
    <div class="gradient-list" data-aos="fade-up">
        <div v-for="(item) in props.data" :key="item.id"
            class="border-top border-bottom border-secondary-light py-4 px-2 my-3 list-item">
            <div class="row align-items-center justify-content-between ">
                <div class="fs-4 fw-bold text-white item-number col-2">
                    {{ item.id }}
                </div>
                <div class="fs-4 text-white fw-regular item-text" :class="secondary ? 'col-7' : 'col-4'">
                    {{ item.title }}
                    <div v-if="secondary"
                        class="small item-description mt-2 fs-6  secondary-description d-none text-secondary-light">
                        {{ item.description }}
                    </div>
                </div>
                <div v-if="secondary" class="col-3">
                    <div class="ring">
                        <img class="arrow-img" src="/images/right-arrow.svg" alt="Right arrow" width="24" height="48" />
                    </div>
                </div>
                <div v-else class="text-secondary-light small item-description col-4 text-end">
                    {{ item.description }}
                </div>

            </div>
        </div>
    </div>

</template>

<style scoped>
.gradient-list {
    background-color: #000;
    position: relative;
    overflow: hidden;
}

.gradient-list::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center,
            rgba(40, 167, 69, 0.3) 0%,
            /* Stronger start */
            rgba(40, 167, 69, 0.1) 40%,
            /* Wider middle spread */
            transparent 96%);
    pointer-events: none;
}





.list-item {
    transition: padding 0.5s ease-in-out;
    /* Smooth padding transition */

    .item-number,
    .item-text {
        transition: color 0.5s ease-in-out;
        /* Color transition */
    }

    .item-description {
        transition: opacity 0.5s ease-in-out;
        /* Opacity transition */
    }


    &:hover {
        padding-top: 40px !important;
        padding-bottom: 40px !important;

        .item-number,
        .item-text {
            color: #25DA4A !important;
        }

        .item-description {
            opacity: 1;
        }

        .secondary-description {
            display: block !important;
        }

        .ring {
            border-color: #25DA4A;

            .arrow-img {
                filter: invert(42%) sepia(53%) saturate(557%) hue-rotate(85deg) brightness(100%) contrast(90%);
            }
        }
    }
}

.ring {
    border: 1px solid white;
    border-radius: 100px;
    /* padding: 10px; */
    width: 50px;
    height: 50px;
    margin-left: auto;
    display: flex;
    justify-content: center;
    transition: border 0.5s ease-in-out;

    .arrow-img {
        transform: rotate(-30deg);
        transition: filter 0.5s ease-in-out;
    }

}
</style>