<template>
    <div 
        class="calendly-inline-widget" 
        data-url="https://calendly.com/mintweb3-support/30min"
        style="min-width: 320px;height: 900px;"
    ></div>
</template>

<script>
export default {
    name: "RequestDemo",
    mounted() {
        // You can dynamically insert the Calendly embed code here
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);
    }
}
</script>