export const en = {
  connect: 'Connect',
  buy: 'Buy',
  buyAndStake: 'Buy and stake',
  buyWithCard: 'Buy with card',
  stake: 'Stake',
  stakeNow: 'Stake now',
  claimTokens: 'Claim',
  claimAndStake: 'Claim and stake',
  claimRewards: 'Claim rewards',
  loading: 'Loading...',
  viewTransaction: 'View transaction',
  close: 'Close',
  copy: 'Copy',
  days: 'Day | Days',
  hours: 'Hour | Hours',
  minutes: 'Minute | Minutes',
  seconds: 'Second | Seconds',
  lastRound: 'Last round',
  developedBy: 'Developed by',
  navbar: {
    home: 'Home',
    staking: 'Staking',
    about: 'About',
    howToBuy: 'How to buy',
    tokenomics: 'Tokenomics',
    roadmap: 'Roadmap',
    faq: 'FAQ'
  },
  presale: {
    howToBuy: 'How to buy',
    presaleOpen: '{token} Presale is now open',
    stage: 'Stage',
    untilPriceIncrease: 'Until price increase',
    untilPriceIncreaseDescription:
      'Price will increase when the timer ends or when the USD raised reaches the target.',
    nextPrice: 'Next price',
    usdtRaised: 'USDT Raised',
    yourPurchased: 'Your purchased {token}',
    yourPurchasedDescription: 'Total purchased tokens with connected address on selected chain',
    yourStakeable: 'Your stakeable {token}',
    yourStakeableDescription:
      'Total unstaked tokens with connected address on selected chain. You can stake your tokens now going to staking page.',
    yourStaked: 'Your staked {token}',
    yourStakedDescription: 'Total staked tokens with connected address on selected chain.',
    becomeAnAffiliate: 'Become an Affiliate!',
  },
  swap: {
    balance: 'Balance',
    youPay: '{token} you pay',
    youReceive: '{token} you receive',
    lessThanMinimumError: 'The amount of { token } to be purchased must be greater than { min }.',
    exceedsMaxError: 'The amount of { token } to be purchased must be less than { max }.',
    notEnoughBalanceError: 'Make sure you have enough balance to purchase.',
    needApprovalDescription:
      "To purchase {token} with {usdToken}, you first need to authorize us to access the {usdToken} in your wallet. You may need to check the wallet app if you're on mobile.",
    waitingApprovalDescription:
      'Authorization is in progress, you will be able to confirm the transaction shortly.',
    needConfirmationDescription:
      "To purchase {tokenSymbol} tokens, please confirm the transaction in your wallet. You may need to check the wallet app if you're on mobile.",
    waitingTransactionDescription:
      '{amount} tokens will be purchased once the transaction is confirmed.',
    openingWertModal: 'Wait while the payment screen opens.',
    waitingWertPayment:
      'To purchase {token} with card, proceed by filling in your informations on the payment screen.',
    waitingWertConfirmation:
      '{amount} tokens will be purchased once the payment provider confirms your payment.',
    successDescription: '{amount} tokens were successfully purchased!',
    confirmationError:
      'We were unable to confirm your transaction, please check if it is stuck in your wallet.',
    errorDescription: 'Something went wrong, please try again.',
    startAgain: 'Start again',
    notEnoughTokens: 'Not enough {token}? Buy or swap now!',
    buyOrSwapTokens: 'Buy or swap tokens'
  },
  presaleOver: {
    presaleOverTitle: '{token} Presale is over',
    presaleOverDescription:
      'Wait until the claim begins to claim and negotiate your {token} tokens. In the meantime, you can stake your purchased tokens and earn rewards.',
    untilClaimStart: 'Until claim start'
  },
  claim: {
    claimOpen: '{token} Claim is now live',
    claimOpenDescription: 'Now you can claim and stake your {token} to earn rewards.',
    importToken:
      'Import the {token} token in your wallet, using the address below, to see your claimed tokens.',
    importTokenDescription:
      'If you don\'t know how to do this, search for "How to import custom tokens" in the wallet you are using.',
    yourClaimable: 'Your claimable {token}',
    yourClaimableDescription:
      'Total tokens available to claim with the connected address. You can claim your tokens to your wallet or stake them directly.',
    yourStaked: 'Your staked {token}',
    yourStakedDescription: 'Total staked tokens with connected address.',
    buyNowOnExchange: 'Buy {token} now on {exchange}',
    stakeNeedConfirmation:
      "To stake your {token} tokens, please confirm the transaction in your wallet. You may need to check the wallet app if you're on mobile. This will stake all your claimable {token} tokens. Tokens will be locked for {lockDays} @.lower:days until you can withdraw.",
    claimNeedConfirmation:
      "To claim your {token} tokens, please confirm the transaction in your wallet. You may need to check the wallet app if you're on mobile. This will claim all your claimable {token} tokens to your connected wallet.",
    stakeWaitingTransaction: 'Tokens will be staked once the transaction is confirmed.',
    claimWaitingTransaction: 'Tokens will be claimed once the transaction is confirmed.',
    stakeSuccess: 'Tokens were successfully staked!',
    claimSuccess:
      'Tokens were successfully claimed! Remember, to see your {token} balance in your wallet, you need to import the token address above.',
    confirmationError:
      'We were unable to confirm your transaction, please check if it is stuck in your wallet.',
    errorDescription: 'Something went wrong, please try again.',
    startAgain: 'Start again'
  },
  staking: {
    welcome: 'Welcome to {token} staking',
    distributionDescription:
      'The distribution of {tokenName} token rewards will occur at a rate of {rewardsPerDay} {tokenSymbol} per day and will be distributed over {duration} year.',
    withdrawStakedTokens: 'Withdraw Staked Tokens',
    stakedBalance: 'Staked balance',
    stakeableBalance: 'Stakeable balance',
    totalRewards: 'Total rewards',
    claimNotStarted:
      'The claim period will begin once the presale ends. Stay tuned for the presale conclusion to start claiming your rewards.',
    totalStaked: 'Total staked',
    percentageOfPool: 'Your % of pool',
    currentRewards: 'Current rewards',
    estimatedRewards: 'Estimated rewards',
    rewardRateDynamic: 'Reward rate is dynamic',
    monthlyRewards: 'Monthly = Rewards % / 12',
    dailyRewards: 'Daily = Rewards % / 365',
    stakeNow: 'Stake your {token} now',
    claimNow: 'Claim your {token} now',
    withdrawNow: 'Withdraw your {token} now',
    fillAmount:
      'Fill in the amount of {token} you wish to stake below and confirm the transaction to start to earning rewards.',
    amount: 'Amount',
    notEnoughBalanceError: 'Make sure you have enough balance to stake.',
    needApprovalDescription:
      "To stake {token}, you first need to authorize us to access the {token} in your wallet. You may need to check the wallet app if you're on mobile.",
    waitingApprovalDescription:
      'Authorization is in progress, you will be able to confirm the transaction shortly.',
    stakeNeedConfirmation:
      "To stake your {token} tokens, please confirm the transaction in your wallet. You may need to check the wallet app if you're on mobile. This will stake all of your {token} tokens. Tokens will be locked for {lockDays} @.lower:days after claiming process goes live.",
    claimNeedConfirmation:
      "To claim your {token} tokens, please confirm the transaction in your wallet. You may need to check the wallet app if you're on mobile. This will claim all of your {token} rewards.",
    withdrawNeedConfirmation:
      "To withdraw your {token} tokens, please confirm the transaction in your wallet. You may need to check the wallet app if you're on mobile. This will withdraw all of your staked {token} tokens.",
    stakeWaitingTransaction: 'Tokens will be staked once the transaction is confirmed.',
    claimWaitingTransaction: 'Rewards will be claimed once the transaction is confirmed.',
    withdrawWaitingTransaction: 'Tokens will be withdrawn once the transaction is confirmed.',
    stakeSuccess: 'Tokens were successfully staked!',
    claimSuccess: 'Tokens were successfully claimed!',
    withdrawSuccess: 'Tokens were successfully withdrawn!',
    confirmationError:
      'We were unable to confirm your transaction, please check if it is stuck in your wallet.',
    errorDescription: 'Something went wrong, please try again.',
    tryAgain: 'Try again'
  },
  airdrop: {
    claimTitle: '{token} Airdrop Claim',
    claimNotStartedDescription:
      'The airdrop claim period will begin once the presale ends. Stay tuned for the presale conclusion to start claiming your rewards.',
    claimStartedDescription: 'You can now claim your airdropped {token} to earn rewards.',
    importToken:
      'Import the {token} token in your wallet, using the address below, to see your claimed tokens.',
    importTokenDescription:
      'If you don\'t know how to do this, search for "How to import custom tokens" in the wallet you are using.',
    yourClaimable: 'Your claimable {token}',
    yourClaimableDescription:
      'Total tokens received during the airdrop available to claim with the connected address.',
    claimNeedConfirmation:
      "To claim your {token} tokens, please confirm the transaction in your wallet. You may need to check the wallet app if you're on mobile. This will claim all your claimable {token} tokens to your connected wallet.",
    claimWaitingTransaction: 'Tokens will be claimed once the transaction is confirmed.',
    claimSuccess:
      'Tokens were successfully claimed! Remember, to see your {token} balance in your wallet, you need to import the token address above.',
    confirmationError:
      'We were unable to confirm your transaction, please check if it is stuck in your wallet.',
    errorDescription: 'Something went wrong, please try again.',
    startAgain: 'Start again'
  },
  status: {
    NEED_APPROVAL: 'Need approval',
    WAITING_APPROVAL: 'Waiting for approval confirmation',
    NEED_CONFIRMATION: 'Need confirmation',
    WAITING_TRANSACTION: 'Waiting for transaction confirmation',
    OPENINIG_WERT_MODAL: 'Opening payment screen',
    WAITING_WERT_PAYMENT: 'Waiting payment',
    WAITING_WERT_CONFIRMATION: 'Waiting payment confirmation',
    SUCCESS: 'Success',
    ERROR: 'Error'
  }
}
