<script setup>
const props = defineProps(['title'])
</script>
<template>
    <button class="btn p-3 text-sm rounded-pill text-black secondary-btn">
        {{ props.title }}
    </button>
</template>

<style scoped>
.secondary-btn {
    background: linear-gradient(94.64deg, #D4D4D3 5.38%, #F7F7EE 88.99%) !important;

}
</style>