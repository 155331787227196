export const OperationType = Object.freeze({
  Buy: Symbol('BUY'),
  BuyAndStake: Symbol('BUY_AND_STAKE'),
  Stake: Symbol('STAKE'),
  Claim: Symbol('CLAIM'),
  Withdraw: Symbol('WITHDRAW')
})

export const WriteContractStatus = Object.freeze({
  NeedApproval: Symbol('NEED_APPROVAL'), //User has to give allowance to our contract
  WaitingApproval: Symbol('WAITING_APPROVAL'), //Waiting approve transaction confirmation
  NeedConfirmation: Symbol('NEED_CONFIRMATION'), //User has to confirm transaction in his wallet
  WaitingTransaction: Symbol('WAITING_TRANSACTION'), //Waiting transaction confirmation
  OpeningWertModal: Symbol('OPENINIG_WERT_MODAL'), //Opening Wert modal to user proceed with payment
  WaitingWertPayment: Symbol('WAITING_WERT_PAYMENT'), //Waiting user make the payment with Wert
  WaitingWertConfirmation: Symbol('WAITING_WERT_CONFIRMATION'), //Waiting Wert confirm the payment
  Success: Symbol('SUCCESS'), //Transaction confirmed successfully
  Error: Symbol('ERROR') //Some error has ouccurred (user canceled, wait timeout, RPC error...)
})

export const TokenType = Object.freeze({
  Main: Symbol('MAIN'), //Main network tokens (ETH, POL, BNB...)
  Token: Symbol('TOKEN'), //ERC20 Tokens (USDC, USDT...)
  Card: Symbol('CARD') //Card
})

export const localeNames = {
  en: 'English',
  pt: 'Portuguese',
  fr: 'French',
  es: 'Spanish',
  de: 'German'
  // Add more locale mappings as needed
}
