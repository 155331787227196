import { chains, wagmiConfig } from '@/plugins/walletconnect'
import { getConnections, switchChain, watchChainId } from '@wagmi/core'
import { onMounted, ref } from 'vue'

//Get last selected chain from local storage to initialize state
const selectedChain = ref()
let unwatch

export function useChain() {
  if (!selectedChain.value) {
    selectedChain.value =
      chains.find(
        (chain) => chain.id === JSON.parse(localStorage.getItem('wagmi.store'))?.state?.chainId
      ) ?? chains[0]
  }

  async function update(chainId) {
    if (chains.find((chain) => chain.id === chainId)) {
      try {
        const connections = getConnections(wagmiConfig)
        await switchChain(wagmiConfig, {
          chainId: chainId,
          connector: connections[0]?.connector
        })

        selectedChain.value = chains.find((chain) => chain.id === chainId)
        return true
      } catch (e) {
        console.error('Cannot switch network', e)
        return false
      }
    }
  }

  //Check if network has been changed manually in the wallet app
  onMounted(() => {
    if (!unwatch) {
      unwatch = watchChainId(wagmiConfig, {
        onChange(chainId) {
          selectedChain.value = chains.find((chain) => chain.id === chainId)
        }
      })
    }
  })

  return { selectedChain, update }
}
