export const de = {
  connect: 'Verbinden',
  buy: 'Kaufen',
  buyAndStake: 'Kaufen und staken',
  buyWithCard: 'Mit Karte kaufen',
  stake: 'Staken',
  stakeNow: 'Stake jetzt',
  claimTokens: 'Beanspruchen',
  claimAndStake: 'Beanspruchen und einsetzen',
  claimRewards: 'Belohnungen beanspruchen',
  loading: 'Laden...',
  viewTransaction: 'Transaktion anzeigen',
  close: 'Schließen',
  copy: 'Kopieren',
  days: 'Tag | Tage',
  hours: 'Stunde | Stunden',
  minutes: 'Minute | Minuten',
  seconds: 'Sekunde | Sekunden',
  lastRound: 'Letzte Runde',
  developedBy: 'Entwickelt von',
  navbar: {
    home: 'Startseite',
    staking: 'Staking',
    about: 'Über uns',
    howToBuy: 'Wie man kauft',
    tokenomics: 'Tokenomics',
    roadmap: 'Fahrplan',
    faq: 'FAQ'
  },
  presale: {
    presaleOpen: '{token}-Vorverkauf ist jetzt geöffnet',
    stage: 'Phase',
    untilPriceIncrease: 'Bis zur Preiserhöhung',
    untilPriceIncreaseDescription:
      'Der Preis wird steigen, wenn der Timer endet oder wenn das angehobene USD-Ziel erreicht ist.',
    nextPrice: 'Nächster Preis',
    usdtRaised: 'USDT gesammelt',
    yourPurchased: 'Deine gekauften {token}',
    yourPurchasedDescription:
      'Gesamte gekaufte Token mit der verbundenen Adresse in der ausgewählten Chain',
    yourStakeable: 'Deine einsetzbaren {token}',
    yourStakeableDescription:
      'Gesamte nicht eingesetzte Token mit der verbundenen Adresse in der ausgewählten Chain. Du kannst deine Token jetzt auf der Einsatzseite einsetzen.',
    yourStaked: 'Deine eingesetzten {token}',
    yourStakedDescription:
      'Gesamte eingesetzte Token mit der verbundenen Adresse in der ausgewählten Chain.'
  },
  swap: {
    balance: 'Guthaben',
    youPay: 'Du zahlst {token}',
    youReceive: 'Du erhältst {token}',
    lessThanMinimumError: 'Der zu kaufende Betrag von { token } muss größer als { min } sein.',
    exceedsMaxError: 'Der zu kaufende Betrag von { token } muss kleiner als { max } sein.',
    notEnoughBalanceError: 'Stelle sicher, dass du genügend Guthaben zum Kauf hast.',
    needApprovalDescription:
      'Um {token} mit {usdToken} zu kaufen, musst du uns zuerst autorisieren, auf das {usdToken} in deinem Wallet zuzugreifen. Du musst möglicherweise die Wallet-App überprüfen, wenn du mobil unterwegs bist.',
    waitingApprovalDescription:
      'Die Autorisierung läuft, du kannst die Transaktion in Kürze bestätigen.',
    needConfirmationDescription:
      'Um {tokenSymbol}-Token zu kaufen, bestätige bitte die Transaktion in deinem Wallet. Du musst möglicherweise die Wallet-App überprüfen, wenn du mobil unterwegs bist.',
    waitingTransactionDescription:
      '{amount} Token werden gekauft, sobald die Transaktion bestätigt wurde.',
    openingWertModal: 'Bitte warten Sie, während der Zahlungsbildschirm geöffnet wird.',
    waitingWertPayment:
      'Um {token} mit Karte zu kaufen, geben Sie Ihre Informationen auf dem Zahlungsbildschirm ein.',
    waitingWertConfirmation:
      '{amount} Token werden gekauft, sobald der Zahlungsanbieter Ihre Zahlung bestätigt.',
    successDescription: '{amount} Token wurden erfolgreich gekauft!',
    confirmationError:
      'Wir konnten deine Transaktion nicht bestätigen. Überprüfe bitte, ob sie in deinem Wallet festhängt.',
    errorDescription: 'Etwas ist schiefgelaufen, bitte versuche es erneut.',
    startAgain: 'Erneut starten',
    notEnoughTokens: 'Nicht genug {token}? Jetzt kaufen oder tauschen!',
    buyOrSwapTokens: 'Token kaufen oder tauschen'
  },
  presaleOver: {
    presaleOverTitle: '{token} Vorverkauf ist abgeschlossen',
    presaleOverDescription:
      'Warten Sie, bis die Abwicklung beginnt, um Ihre {token}-Tokens zu beanspruchen und zu handeln. In der Zwischenzeit können Sie Ihre gekauften Tokens staken und Belohnungen verdienen.',
    untilClaimStart: 'Bis zum Beginn der Abwicklung'
  },
  claim: {
    claimOpen: '{token}-Beanspruchung ist jetzt live',
    claimOpenDescription:
      'Du kannst jetzt deine {token} beanspruchen und einsetzen, um Belohnungen zu verdienen.',
    importToken:
      'Importiere das {token}-Token in dein Wallet, indem du die Adresse unten verwendest, um deine beanspruchten Token zu sehen.',
    importTokenDescription:
      'Wenn du nicht weißt, wie das geht, suche nach "Wie importiert man benutzerdefinierte Token" im Wallet, das du verwendest.',
    yourClaimable: 'Deine beanspruchbaren {token}',
    yourClaimableDescription:
      'Gesamtzahl der verfügbaren Token zum Beanspruchen mit der verbundenen Adresse. Du kannst deine Token in dein Wallet beanspruchen oder direkt einsetzen.',
    yourStaked: 'Deine eingesetzten {token}',
    yourStakedDescription: 'Gesamte eingesetzte Token mit der verbundenen Adresse.',
    buyNowOnExchange: 'Kaufe {token} jetzt auf {exchange}',
    stakeNeedConfirmation:
      'Um deine {token} zu setzen, bestätige die Transaktion in deinem Wallet. Du musst möglicherweise die Wallet-App überprüfen, wenn du mobil unterwegs bist. Dies wird alle deine beanspruchbaren {token}-Token einsetzen. Token werden für {lockDays} @.lower:days gesperrt, bis du sie abheben kannst.',
    claimNeedConfirmation:
      'Um deine {token} zu beanspruchen, bestätige die Transaktion in deinem Wallet. Du musst möglicherweise die Wallet-App überprüfen, wenn du mobil unterwegs bist. Dies wird alle deine beanspruchbaren {token}-Token in dein verbundenes Wallet beanspruchen.',
    stakeWaitingTransaction: 'Die Token werden eingesetzt, sobald die Transaktion bestätigt wurde.',
    claimWaitingTransaction:
      'Die Token werden beansprucht, sobald die Transaktion bestätigt wurde.',
    stakeSuccess: 'Token wurden erfolgreich eingesetzt!',
    claimSuccess:
      'Token wurden erfolgreich beansprucht! Denke daran, um dein {token}-Guthaben in deinem Wallet zu sehen, musst du die Token-Adresse oben importieren.',
    confirmationError:
      'Wir konnten deine Transaktion nicht bestätigen. Überprüfe bitte, ob sie in deinem Wallet festhängt.',
    errorDescription: 'Etwas ist schiefgelaufen, bitte versuche es erneut.',
    startAgain: 'Erneut starten'
  },
  staking: {
    welcome: 'Willkommen beim {token}-Einsatz',
    distributionDescription:
      'Die Verteilung der {tokenName}-Token-Belohnungen erfolgt mit einer Rate von {rewardsPerDay} {tokenSymbol} pro Tag und wird über {duration} Jahr(e) verteilt.',
    withdrawStakedTokens: 'Eingesetzte Token abheben',
    stakedBalance: 'Eingesetztes Guthaben',
    stakeableBalance: 'Verfügbares Guthaben zum Einsatz',
    totalRewards: 'Gesamte Belohnungen',
    claimNotStarted:
      'Die Beanspruchungsperiode beginnt nach dem Ende des Vorverkaufs. Bleibe dran für den Abschluss des Vorverkaufs, um mit dem Beanspruchen deiner Belohnungen zu beginnen.',
    totalStaked: 'Gesamtmenge im Einsatz',
    percentageOfPool: 'Dein % des Pools',
    currentRewards: 'Aktuelle Belohnungen',
    estimatedRewards: 'Geschätzte Belohnungen',
    rewardRateDynamic: 'Die Belohnungsrate ist dynamisch',
    monthlyRewards: 'Monatlich = Belohnungen % / 12',
    dailyRewards: 'Täglich = Belohnungen % / 365',
    stakeNow: 'Setze deine {token} jetzt ein',
    claimNow: 'Beanspruche deine {token} jetzt',
    withdrawNow: 'Hebe deine {token} jetzt ab',
    fillAmount:
      'Gib unten den Betrag an {token} ein, den du einsetzen möchtest, und bestätige die Transaktion, um mit dem Verdienen von Belohnungen zu beginnen.',
    amount: 'Betrag',
    notEnoughBalanceError: 'Stelle sicher, dass du genügend Guthaben zum Einsetzen hast.',
    needApprovalDescription:
      'Um {token} einzusetzen, musst du uns zuerst autorisieren, auf das {token} in deinem Wallet zuzugreifen. Du musst möglicherweise die Wallet-App überprüfen, wenn du mobil unterwegs bist.',
    waitingApprovalDescription:
      'Die Autorisierung läuft, du kannst die Transaktion in Kürze bestätigen.',
    stakeNeedConfirmation:
      'Um deine {token}-Token einzusetzen, bestätige die Transaktion in deinem Wallet. Du musst möglicherweise die Wallet-App überprüfen, wenn du mobil unterwegs bist. Dies wird alle deine {token}-Token einsetzen. Token werden für {lockDays} @.lower:days gesperrt, nachdem der Beanspruchungsprozess beginnt.',
    claimNeedConfirmation:
      'Um deine {token}-Token zu beanspruchen, bestätige die Transaktion in deinem Wallet. Du musst möglicherweise die Wallet-App überprüfen, wenn du mobil unterwegs bist. Dies wird alle deine {token}-Belohnungen beanspruchen.',
    withdrawNeedConfirmation:
      'Um deine {token}-Token abzuheben, bestätige die Transaktion in deinem Wallet. Du musst möglicherweise die Wallet-App überprüfen, wenn du mobil unterwegs bist. Dies wird alle deine eingesetzten {token}-Token abheben.',
    stakeWaitingTransaction: 'Die Token werden eingesetzt, sobald die Transaktion bestätigt wurde.',
    claimWaitingTransaction:
      'Die Belohnungen werden beansprucht, sobald die Transaktion bestätigt wurde.',
    withdrawWaitingTransaction:
      'Die Token werden abgehoben, sobald die Transaktion bestätigt wurde.',
    stakeSuccess: 'Token wurden erfolgreich eingesetzt!',
    claimSuccess: 'Token wurden erfolgreich beansprucht!',
    withdrawSuccess: 'Token wurden erfolgreich abgehoben!',
    confirmationError:
      'Wir konnten deine Transaktion nicht bestätigen. Überprüfe bitte, ob sie in deinem Wallet festhängt.',
    errorDescription: 'Etwas ist schiefgelaufen, bitte versuche es erneut.',
    tryAgain: 'Erneut versuchen'
  },
  airdrop: {
    claimTitle: '{token} Airdrop-Abwicklung',
    claimNotStartedDescription:
      'Die Airdrop-Abwicklungsperiode beginnt, sobald der Vorverkauf abgeschlossen ist. Bleiben Sie dran, um Ihre Belohnungen nach Abschluss des Vorverkaufs zu beanspruchen.',
    claimStartedDescription:
      'Sie können jetzt Ihre {token}, die im Airdrop erhalten wurden, beanspruchen, um Belohnungen zu verdienen.',
    importToken:
      'Importieren Sie das {token}-Token in Ihre Wallet, indem Sie die unten stehende Adresse verwenden, um Ihre beanspruchten Tokens zu sehen.',
    importTokenDescription:
      'Wenn Sie nicht wissen, wie das geht, suchen Sie nach "Wie man benutzerdefinierte Tokens importiert" in der Wallet, die Sie verwenden.',
    yourClaimable: 'Ihre beanspruchbaren {token}',
    yourClaimableDescription:
      'Gesamte während des Airdrops erhaltene Tokens, die mit der verbundenen Adresse beansprucht werden können.',
    claimNeedConfirmation:
      'Um Ihre {token}-Tokens zu beanspruchen, bestätigen Sie bitte die Transaktion in Ihrer Wallet. Möglicherweise müssen Sie die Wallet-App überprüfen, wenn Sie mobil sind. Dies beansprucht alle Ihre verfügbaren {token}-Tokens in Ihrer verbundenen Wallet.',
    claimWaitingTransaction: 'Die Tokens werden beansprucht, sobald die Transaktion bestätigt ist.',
    claimSuccess:
      'Tokens wurden erfolgreich beansprucht! Denken Sie daran, um Ihr {token}-Guthaben in Ihrer Wallet zu sehen, müssen Sie die oben genannte Token-Adresse importieren.',
    confirmationError:
      'Wir konnten Ihre Transaktion nicht bestätigen, überprüfen Sie bitte, ob sie in Ihrer Wallet feststeckt.',
    errorDescription: 'Etwas ist schiefgelaufen, bitte versuchen Sie es erneut.',
    startAgain: 'Neu starten'
  },
  status: {
    NEED_APPROVAL: 'Genehmigung erforderlich',
    WAITING_APPROVAL: 'Warte auf Genehmigungsbestätigung',
    NEED_CONFIRMATION: 'Bestätigung erforderlich',
    WAITING_TRANSACTION: 'Warte auf Transaktionsbestätigung',
    OPENINIG_WERT_MODAL: 'Zahlungsbildschirm wird geöffnet',
    WAITING_WERT_PAYMENT: 'Warten auf Zahlung',
    WAITING_WERT_CONFIRMATION: 'Warten auf Zahlungsbestätigung',
    SUCCESS: 'Erfolg',
    ERROR: 'Fehler'
  }
}
