import { hasClaimStarted } from '@/utils/helpers'
import { reconnect } from '@wagmi/core'
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/vue'
import {
  arbitrum,
  arbitrumSepolia,
  avalanche,
  avalancheFuji,
  base,
  baseSepolia,
  bsc,
  bscTestnet,
  mainnet,
  optimism,
  optimismSepolia,
  polygon,
  polygonAmoy,
  sepolia
} from 'viem/chains'
import { transportsMain, transportsTest } from '../../utils/constants/transports'
// import { createAppKit } from '@reown/appkit/vue'
// import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'

// 1. Get projectId from https://cloud.reown.com
const projectId = import.meta.env.VITE_REOWN_PROJECT_ID

// 2. Create your application's metadata object
const metadata = {
  name: 'Mint Web3',
  description: 'Web3 platform from Mint Web3',
  url: import.meta.env.VITE_APP_URL,
  icons: [import.meta.env.VITE_APP_URL + '/favicon.ico']
}

export const chains =
  import.meta.env.VITE_TESTNET === 'true'
    ? hasClaimStarted()
      ? [
          bscTestnet
          // arbitrumSepolia
        ]
      : [
          bscTestnet,
          sepolia,
          arbitrumSepolia,
          polygonAmoy,
          baseSepolia,
          optimismSepolia,
          avalancheFuji
        ]
    : hasClaimStarted()
      ? [bsc]
      : [bsc, mainnet, polygon, arbitrum, optimism, base, avalanche]

// 3. Create Wagmi Adapter
// const wagmiAdapter = new WagmiAdapter({
//   projectId,
//   networks: chains,
//   transports: import.meta.env.VITE_TESTNET === 'true' ? transportsTest : transportsMain
// })
// export const wagmiConfig = wagmiAdapter.wagmiConfig

export const wagmiConfig = defaultWagmiConfig({
  projectId,
  chains,
  metadata,
  enableCoinbase: true,
  enableInjected: true,
  auth: {
    email: false,
    socials: []
  },
  enableWalletConnect: true,
  transports: import.meta.env.VITE_TESTNET === 'true' ? transportsTest : transportsMain
})

reconnect(wagmiConfig)

// 4. Create a AppKit instance
// export const appKit = createAppKit({
//   adapters: [wagmiAdapter],
//   projectId,
//   networks: chains,
//   metadata,
//   features: {
//     email: false,
//     socials: [],
//     emailShowWallets: false,
//     swaps: false,
//     onramp: false
//   },
//   allowUnsupportedChain: false,
//   featuredWalletIds: [
//     'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b',
//     'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
//     '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
//     'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
//     'fe68cea63541aa53ce020de7398968566dfe8f3725663a564cac89490247ed49'
//   ],
//   allWallets: 'SHOW',
//   coinbasePreference: 'eoaOnly',
//   themeVariables: {
//     '--w3m-z-index': 1100,
//     '--w3m-accent': '#56ce50'
//   }
// })

export const web3modal = createWeb3Modal({
  wagmiConfig,
  projectId,
  // defaultChain: chains[0],
  allowUnsupportedChain: false,
  enableSwaps: false,
  enableOnramp: false,
  allWallets: 'SHOW',
  featuredWalletIds: [
    'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b',
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa'
  ],
  enableAnalytics: false,
  coinbasePreference: 'eoaOnly',
  themeVariables: {
    '--w3m-z-index': 1100,
    '--w3m-accent': '#56ce50'
  }
})
