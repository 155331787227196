<script setup>
</script>

<template>
    <button class="btn p-2 px-3  rounded-pill outlined-btn text-white" style="font-size: 14px;">
        <slot name="title" />
    </button>
</template>

<style>
.outlined-btn {
    /* background: linear-gradient(90.54deg, rgba(255, 255, 255, 0.08) 1.67%, rgba(255, 255, 255, 0.04) 98.33%); */
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.outlined-btn:hover {
    border: 1px solid rgba(255, 255, 255, 1);
}

.outlined-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1px;
    background: linear-gradient(90.47deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.0) 100%);
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
    pointer-events: none;
    z-index: -1;
}


.border-gradient {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 16px;
    padding: 2px;
    background: linear-gradient(90.47deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%);
    z-index: -1;
}
</style>